<template>
  <!-- ;margin-top: -15px -->
  <div v-if="questionData != null" style="padding: 10px">
    <div class="Subject-topicAsk">
      <span v-html="questionData.content"></span>
      <!-- <i
        class="el-icon-question"
        style="float: right"
        title="以下是匹配题的选项，请将下列选项拖到下方表格中的适当位置。"
      /> -->
      <el-tooltip
        class="item"
        effect="dark"
        style="float: right"
        content="以下是匹配题的选项，请将下列选项拖到下方表格中的适当位置。"
        placement="top-end"
      >
        <i class="el-icon-question" />
      </el-tooltip>
    </div>
    <el-row class="comprehensive">
      <draggable v-model="matcheOptions" group="people" itemKey="itemId">
        <transition-group class="h300BK_top">
          <div
            v-for="matcheOptionItem in matcheOptions"
            :key="matcheOptionItem.itemId"
            class="w25bf"
          >
            <div class="comprehensive-item" :title="matcheOptionItem.itemValue">
              {{ matcheOptionItem.itemValue }}
            </div>
          </div>
        </transition-group>
      </draggable>
    </el-row>
    <div
      v-for="row in answerRow"
      :key="row"
      class="comprehensive br6"
      type="flex"
      align-items="stretch"
    >
      <!-- :style="colStyle[(cell - 1) % 3]" -->
      <el-row style="width: 100%">
        <el-col class="br6_header">
          {{ questionMatches[row - 1].match }}
        </el-col>
      </el-row>

      <el-row
        style="width: 100%"
        v-for="subRow in Math.ceil(
          questionMatches[row - 1].subItems.length / 3
        )"
        :key="subRow"
      >
        <!-- <el-col class="br6_header">
          {{ questionMatches[(row - 1) * 3 + cell - 1].match }}
        </el-col> -->
        <el-col
          :span="8"
          v-for="cell in subRow * 3 > questionMatches[row - 1].subItems.length
            ? questionMatches[row - 1].subItems.length - (subRow - 1) * 3
            : 3"
          :key="cell"
        >
          <el-popover
            v-model="
              questionMatches[row - 1].subItems[(subRow - 1) * 3 + cell - 1]
                .nameVisible
            "
            v-if="matcheOptions.length > 0"
            placement="right"
            width="400"
            trigger="click"
          >
            <div class="w50bf-item-show">
              <div
                v-for="(itemEntrie, i) in matcheOptions"
                :key="i"
                class="w50bfContent"
              >
                <div
                  @click="
                    addItemFn(
                      itemEntrie,
                      questionMatches[row - 1].subItems[
                        (subRow - 1) * 3 + cell - 1
                      ],
                      matcheOptions
                    )
                  "
                  :title="itemEntrie.itemValue"
                  class="w50bf-item"
                >
                  {{ itemEntrie.itemValue }}
                </div>
              </div>
            </div>
            <div slot="reference">
              <draggable
                style="padding: 4px"
                v-model="
                  questionMatches[row - 1].subItems[(subRow - 1) * 3 + cell - 1]
                    .answer
                "
                group="people"
                itemKey="itemId"
                @add="
                  addDraggable(
                    questionMatches[row - 1].subItems[
                      (subRow - 1) * 3 + cell - 1
                    ].answer
                  )
                "
              >
                <transition-group class="h300BK">
                  <div
                    class="w25bf"
                    v-for="answerItem in questionMatches[row - 1].subItems[
                      (subRow - 1) * 3 + cell - 1
                    ].answer"
                    :key="answerItem.itemId"
                  >
                    <div
                      class="comprehensive-item"
                      :title="answerItem.itemValue"
                    >
                      {{ answerItem.itemValue }}
                    </div>
                  </div>
                </transition-group>
              </draggable>
            </div>
          </el-popover>

          <draggable
            v-else
            style="padding: 4px"
            v-model="
              questionMatches[row - 1].subItems[(subRow - 1) * 3 + cell - 1]
                .answer
            "
            group="people"
            itemKey="itemId"
            @add="
              addDraggable(
                questionMatches[row - 1].subItems[(subRow - 1) * 3 + cell - 1]
                  .answer
              )
            "
          >
            <transition-group class="h300BK">
              <div
                class="w25bf"
                v-for="answerItem in questionMatches[row - 1].subItems[
                  (subRow - 1) * 3 + cell - 1
                ].answer"
                :key="answerItem.itemId"
              >
                <div class="comprehensive-item" :title="answerItem.itemValue">
                  {{ answerItem.itemValue }}
                </div>
              </div>
            </transition-group>
          </draggable>
        </el-col>
      </el-row>
      <!-- <el-col
        :span="8"
        style="border-left: 1px solid #aeaeae; border-right: 1px solid #aeaeae"
      >
        <div class="br6_header">借</div>
        <div
          v-for="accountEntriesItem in itemAccount.accountEntries"
          :key="accountEntriesItem.id"
        >
          <draggable
            style="padding: 4px"
            v-model="accountEntriesItem.accountEntriesValues"
            :group="'people' + itemAccountIndex"
            itemKey="id"
          >
            <transition-group class="h300BK">
              <div
                class="w25bf"
                v-for="accountEntriesValueItem in accountEntriesItem.accountEntriesValues"
                :key="accountEntriesValueItem.id"
              >
                <div class="comprehensive-item">
                  {{ accountEntriesValueItem.name }}
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="br6_header">贷</div>
        <div
          v-for="accountEntriesItem in itemAccount.accountEntries"
          :key="accountEntriesItem.id"
        >
          <draggable
            style="padding: 4px"
            v-model="accountEntriesItem.accountEntriesTypes"
            :group="'people' + itemAccountIndex"
            itemKey="id"
          >
            <transition-group class="h300BK">
              <div
                class="w25bf"
                v-for="matcheOptionsItem in matcheOptions"
                :key="matcheOptionsItem.id"
              >
                <div class="comprehensive-item">
                  {{ matcheOptionsItem.itemValue }}
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </el-col> -->
    </div>
    <div class="outlineBut" v-if="topicType != null && !isCheck && !isTest">
      <button class="but2" type="button" @click="check">核对</button>
    </div>
    <!-- <answer-result-tip
      style="margin-top: 20px"
      :questionAnswer="questionAnswer"
      :isAnswerSubmit="isAnswerSubmit"
      @show-or-hide-comment="showOrHideComment"
    />
    <div v-if="isShowComment" class="Subject-bor">
      <div class="Subject-pl36">答案:</div>
      <div class="Subject-borMt15">
        <el-row
          v-for="row in answerRow"
          :key="row"
          class="comprehensive br6"
          type="flex"
          align-items="stretch"
        >
          <el-col
            v-for="cell in row * 3 > questionMatches.length
              ? questionMatches.length - (row - 1) * 3
              : 3"
            :key="cell"
            :span="8"
            :style="colStyle[(cell - 1) % 3]"
          >
            <div class="br6_header">
              {{ questionMatches[(row - 1) * 3 + cell - 1].match }}
            </div>
            <div
              v-for="(subItem, subItemIndex) in questionMatches[
                (row - 1) * 3 + cell - 1
              ].subItems"
              :key="subItemIndex"
            >
              <div style="padding: 4px" class="w25bf">
                <div class="comprehensive-item" :title="subItem.itemValue">
                  {{ subItem.itemValue }}
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div
        v-if="questionData.comment != ''"
        class="Subject-pl36"
        style="margin-top: 20px"
      >
        注解:
      </div>
      <div v-if="questionData.comment != ''" class="Subject-borMt15">
        <div class="explainCenter" v-html="questionData.comment"></div>
      </div>
    </div> -->

    <div
      style="margin-top: 20px"
      v-if="isAnswerSubmit || isCheck"
      :class="getAnswerStyle(questionAnswer.ret)"
    >
      <i></i>
      <span style="margin-left: 10px">{{
        getAnswerTip(questionAnswer.ret)
      }}</span>
      <span style="margin-left: 50px"
        ><span v-if="topicType == 'COMPREHENSIVE'"
          >第{{ questionIndex + 1 }}小</span
        ><span v-else>本</span>题得分率为：<span style="font-weight: 800"
          >{{ scoringRate }}%</span
        ></span
      >
      <div
        class="closeBut"
        v-if="topicType != null && isCheck && !isAnswerSubmit"
      >
        <button class="but2" type="button" @click="isCheck = false">
          关闭
        </button>
      </div>
      <div class="Subject-bor">
        <div class="Subject-pl36">答案:</div>
        <div class="Subject-borMt15">
          <div
            v-for="row in answerRow"
            :key="row"
            class="comprehensive br6"
            type="flex"
            align-items="stretch"
          >
            <!-- :style="colStyle[(cell - 1) % 3]" -->
            <el-row style="width: 100%">
              <el-col class="br6_header">
                {{ questionMatches[row - 1].match }}
              </el-col>
            </el-row>
            <el-row
              style="width: 100%"
              v-for="subRow in Math.ceil(
                questionMatches[row - 1].subItems.length / 3
              )"
              :key="subRow"
              :span="24"
            >
              <!--cell in subRow ==
                Math.ceil(questionMatches[row - 1].subItems.length / 3)
                  ? questionMatches[row - 1].subItems.length % 3
                  : 3-->
              <el-col
                :span="8"
                v-for="cell in subRow * 3 >
                questionMatches[row - 1].subItems.length
                  ? questionMatches[row - 1].subItems.length - (subRow - 1) * 3
                  : 3"
                :key="cell"
              >
                <div
                  v-if="
                    questionMatches[row - 1].subItems[
                      (subRow - 1) * 3 + cell - 1
                    ].id != -1
                  "
                  style="padding: 4px"
                  class="w25bf"
                >
                  <div
                    class="comprehensive-item"
                    :title="
                      questionMatches[row - 1].subItems[
                        (subRow - 1) * 3 + cell - 1
                      ].itemValue
                    "
                  >
                    {{
                      questionMatches[row - 1].subItems[
                        (subRow - 1) * 3 + cell - 1
                      ].itemValue
                    }}
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div
          v-if="isRichTextNotNull(questionData.comment)"
          class="Subject-pl36"
          style="margin-top: 20px"
        >
          注解:
        </div>
        <div
          v-if="isRichTextNotNull(questionData.comment)"
          class="Subject-borMt15"
        >
          <div class="explainCenter" v-html="questionData.comment"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import AnswerResultTip from "./AnswerResultTip.vue";
import { isRichTextNotNull } from "@/utils/validate";
import draggable from "vuedraggable";
export default {
  name: "MatchingItem",
  components: { draggable }, //AnswerResultTip
  props: {
    questionData: {
      type: Object,
      default: null,
    },
    presentation: {
      type: String,
      default: null,
    },
    isShowSubjecTitle: {
      type: Boolean,
      default: true,
    },
    isAnswerSubmit: {
      type: Boolean,
      default: false,
    },
    isShowFavorite: {
      //是否显示收藏操作
      type: Boolean,
      default: true,
    },
    refKind: {
      type: String,
      default: null,
    },
    refId: {
      type: Number,
      default: -1,
    },
    topicIndex: {
      type: Number,
      default: 0,
    },
    questionIndex: {
      type: Number,
      default: 0,
    },
    topicType: {
      type: String,
      default: null,
    },
    isTest: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nameVisible: true,
      scoringRate: 100,
      isShowComment: false,
      questionMatches: [],
      matcheOptions: [],
      answerRow: 1,
      isCheck: false,
      questionAnswer: {
        approved: true,
        matchesAnswers: [],
        questionId: 0,
        ret: "BLANK",
        scores: 0,
        weight: 0,
      },
      correctAnswers: [],
      colStyle: [
        { "border-right": "1px solid #aeaeae" },
        {
          "border-right": "1px solid #aeaeae",
          "border-left": "1px solid #aeaeae;",
        },
        {},
      ],
    };
  },
  watch: {
    isAnswerSubmit: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        if (val) {
          this.isCheck = true;
        }
      },
    },
    questionMatches: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        let questionMatchesLength = this.questionMatches.length;
        this.questionAnswer.matchesAnswers = [];
        let sortMatchesAnswers = [];
        let matchesAnswers = this.questionAnswer.matchesAnswers;
        for (let i = 0; i < questionMatchesLength; i++) {
          matchesAnswers.push({
            category: this.questionMatches[i].match,
            subItems: [],
          });
          sortMatchesAnswers.push({
            category: this.questionMatches[i].match,
            subItems: [],
          });
          let subItemsLength = this.questionMatches[i].subItems.length;
          this.questionMatches[i].subItems;
          for (let j = 0; j < subItemsLength; j++) {
            let answer = this.questionMatches[i].subItems[j].answer;
            let answerLength = answer.length;
            if (answerLength > 0) {
              matchesAnswers[matchesAnswers.length - 1].subItems.push(
                answer[0]
              );
              sortMatchesAnswers[sortMatchesAnswers.length - 1].subItems.push(
                answer[0]
              );
            } else {
              matchesAnswers[matchesAnswers.length - 1].subItems.push({
                itemId: -1,
                itemValue: "",
              });
              sortMatchesAnswers[sortMatchesAnswers.length - 1].subItems.push({
                id: -1,
                itemId: -1,
                itemValue: "",
              });
            }
          }
          sortMatchesAnswers[sortMatchesAnswers.length - 1].subItems.sort(
            function (a, b) {
              if (a.itemId > b.itemId) {
                return -1;
              } else if (a.itemId < b.itemId) {
                return 1;
              } else {
                return 0;
              }
            }
          );
        }
        let correctAnswersLength = this.correctAnswers.length;
        let correctAnswersSubItemsLength;
        let correctCounts = 0;
        let questionCounts = correctAnswersLength;
        let isCorrect;
        for (let i = 0; i < correctAnswersLength; i++) {
          correctAnswersSubItemsLength = this.correctAnswers[i].subItems.length;
          isCorrect = true;
          for (let j = 0; j < correctAnswersSubItemsLength; j++) {
            if (
              this.correctAnswers[i].subItems[j].itemId !=
              sortMatchesAnswers[i].subItems[j].itemId
            ) {
              isCorrect = false;
            }
          }
          if (isCorrect) {
            correctCounts++;
          }
        }
        // let correctCounts = 0;
        // let questionCounts = 0;
        // for (let i = 0; i < questionMatchesLength; i++) {
        //   let subItemsLength = this.questionMatches[i].subItems.length;
        //   for (let j = 0; j < subItemsLength; j++) {
        //     if (this.questionMatches[i].subItems[j].id != -1) {
        //       questionCounts++;
        //     }
        //     console.log(
        //       "matchesAnswers itemId:" +
        //         this.questionAnswer.matchesAnswers[i].subItems[j].itemId +
        //         "questionMatches: itemId:" +
        //         this.questionMatches[i].subItems[j].itemId
        //     );
        //     if (
        //       this.questionMatches[i].subItems[j].itemId ==
        //         this.questionAnswer.matchesAnswers[i].subItems[j].itemId &&
        //       this.questionMatches[i].subItems[j].id != -1
        //     ) {
        //       correctCounts++;
        //     }
        //   }
        // }
        if (this.questionData.score != 0 && correctCounts != 0) {
          this.questionAnswer.scores = this.questionData.score / correctCounts;
        }
        if (correctCounts > 0 && questionCounts == correctCounts) {
          this.questionAnswer.ret = "CORRECT";
          this.scoringRate = 100;
        } else if (correctCounts > 0) {
          this.scoringRate = (correctCounts / questionCounts) * 100;
          this.questionAnswer.ret = "PARTIAL";
        } else {
          this.scoringRate = (correctCounts / questionCounts) * 100;
          if (this.matcheOptions.length == questionCounts) {
            this.questionAnswer.ret = "BLANK";
          } else {
            this.questionAnswer.ret = "INCORRECT";
          }
        }
        this.questionAnswer.scoringRate = this.scoringRate;
        if (
          this.scoringRate.toString().indexOf(".") != -1 &&
          (this.scoringRate + "").split(".")[1].length > 2
        ) {
          this.scoringRate = this.scoringRate.toFixed(2);
        }
        this.$emit("set-answers", this.questionIndex, this.questionAnswer);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.questionAnswer.questionId = this.questionData.id;
    this.initAnswer();
  },
  methods: {
    initAnswer() {
      this.isCheck = false;
      this.questionMatches = [];
      this.correctAnswers = [];
      this.matcheOptions = []
      let matches = this.questionData.matches;
      let matchesLength = matches.length;
      let distractors = [];
      for (let i = 0; i < matchesLength; i++) {
        if (matches[i].match == "") {
          distractors.push({
            itemId: matches[i].id,
            itemValue: matches[i].content,
          });
        }
      }
      for (let i = 0; i < matchesLength; i++) {
        let isAt = false;
        let questionMatchesLength = this.questionMatches.length;
        for (let j = 0; j < questionMatchesLength; j++) {
          // &&matches[i].match != "" add 2022-07-01
          if (
            matches[i].match == this.questionMatches[j].match &&
            matches[i].match != ""
          ) {
            this.questionMatches[j].subItems.push({
              id: 0,
              itemId: matches[i].id,
              itemValue: matches[i].content,
              //answer: [],
            });
            this.$set(
              this.questionMatches[j].subItems[
                this.questionMatches[j].subItems.length - 1
              ],
              "answer",
              []
            );
            this.$set(
              this.questionMatches[j].subItems[
                this.questionMatches[j].subItems.length - 1
              ],
              "nameVisible",
              false
            );
            isAt = true;
            this.correctAnswers[this.correctAnswers.length - 1].subItems.push({
              id: 0,
              itemId: matches[i].id,
              itemValue: matches[i].content,
            });
            break;
          }
        }
        if (!isAt && matches[i].match != "") {
          this.correctAnswers.push({
            category: matches[i].match,
            subItems: [],
          });
          this.questionMatches.push({
            match: matches[i].match,
          });
          this.$set(
            this.questionMatches[this.questionMatches.length - 1],
            "subItems",
            []
          );
          this.questionMatches[this.questionMatches.length - 1].subItems.push({
            id: 0,
            itemId: matches[i].id,
            itemValue: matches[i].content,
            //answer: [],
          });
          this.$set(
            this.questionMatches[this.questionMatches.length - 1].subItems[
              this.questionMatches[this.questionMatches.length - 1].subItems
                .length - 1
            ],
            "answer",
            []
          );

          this.correctAnswers[this.correctAnswers.length - 1].subItems.push({
            id: 0,
            itemId: matches[i].id,
            itemValue: matches[i].content,
            //answer: [],
          });
          // this.questionMatches.push({
          //   match: matches[i].match,
          //   subItems: [
          //     {
          //       itemId: matches[i].id,
          //       itemValue: matches[i].content,
          //       answer: [],
          //     },
          //   ],
          // });
        }
        this.matcheOptions.push({
          itemId: matches[i].id,
          itemValue: matches[i].content,
        });
      }
      //答案乱序
      this.matcheOptions.sort(function () {
        return 0.5 - Math.random();
      });
      let correctAnswersLength = this.correctAnswers.length;
      for (let i = 0; i < correctAnswersLength; i++) {
        for (let k = 0; k < distractors.length; k++) {
          this.questionMatches[i].subItems.push({
            id: -1,
            itemId: distractors[k].itemId,
            itemValue: distractors[k].itemValue,
            //answer: [],
          });
          this.$set(
            this.questionMatches[i].subItems[
              this.questionMatches[i].subItems.length - 1
            ],
            "answer",
            []
          );
          this.correctAnswers[i].subItems.push({
            id: -1,
            itemId: -1,
            itemValue: "",
            //answer: [],
          });
        }
        this.correctAnswers[i].subItems.sort(function (a, b) {
          if (a.itemId > b.itemId) {
            return -1;
          } else if (a.itemId < b.itemId) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      this.answerRow = this.questionMatches.length;
    },
    addItemFn(row, list, info) {
      this.$set(list, "answer", [row]);
      var tr = false;
      var index = 0;
      info &&
        info.map((r, i) => {
          if (r.itemId == row.itemId) {
            tr = true;
            index = i;
          }
        });
      if (tr) {
        this.matcheOptions.splice(index, 1);
      }
      list.nameVisible = false;
    },
    getEntriesOptionsList(options, type) {
      let list = [];
      for (let i = 0; i < options.length; i++) {
        if (options[i].id.indexOf("itemValue") != -1 && type == "itemValue") {
          list.push(options[i]);
        } else if (
          options[i].id.indexOf("itemValue") == -1 &&
          type == "itemValue"
        ) {
          list.push(options[i]);
        }
      }
      return list;
    },
    isRichTextNotNull(comment) {
      return isRichTextNotNull(comment);
    },
    showOrHideComment(bool) {
      this.isShowComment = bool;
    },
    addDraggable(answer) {
      if (answer.length > 1) {
        this.matcheOptions.push(answer[1]);
        answer.splice(1, 1);
      }
    },
    answerSubmit() {
      this.isAnswerSubmit = true;
    },
    getAnswerStyle(ret) {
      if (ret == "CORRECT") {
        return ["TitleRightItem1"];
      } else if (ret == "BLANK" || ret == "INCORRECT" || ret == "PARTIAL") {
        return ["TitleErrorItem1"];
      } /*else if (ret == "PARTIAL") {
        return ["TitleReportItem1"];
      }*/
    },
    getAnswerTip(ret) {
      if (ret == "CORRECT") {
        return "正确!";
      } else if (ret == "BLANK" || ret == "INCORRECT" || ret == "PARTIAL") {
        return "错误!";
      } /*else if (ret == "PARTIAL") {
        return "正确部分！";
      }*/
    },
    check() {
      this.isCheck = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.Subject-title {
  height: 30px;
  margin-top: 20px;
  .Subject-title-fl {
    font-size: 28px;
    font-weight: bold;
    color: #575b67;
    float: left;
    line-height: 30px;
  }
  .Subject-title-fr {
    float: right;
    height: 30px;
    background: #636363;
    line-height: 30px;
    border-radius: 16px;
    padding: 0 10px;
    i {
      margin-top: 3px;
      float: left;
    }
    span {
      color: #ffffff;
      font-size: 12px;
      font-weight: normal;
      margin-left: 5px;
      float: left;
    }
  }
}
.Subject-or-title {
  line-height: 30px;
  padding: 20px 0 5px 0;
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
.Subject-topicAsk:after {
  content: "";
  /*转换为一个块元素*/
  display: block;
  /*清除两侧的浮动*/
  clear: both;
}
.Subject-topicAsk {
  //margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #060606;
  span {
    width: calc(100% - 20px);
    float: left;
    height: auto;
  }
  // p {
  //   margin: 0;
  //    display: inline;
  //   white-space: pre-line;
  // }
}
.Subject-or-text {
  padding-top: 10px;
  textarea {
    padding: 5px 10px;
    border: 1px solid #707070;
    height: 350px;
    line-height: 24px;
    font-size: 16px;
    width: 100%;
  }
}
.mt15 {
  margin-top: 15px;
}
.lhpbtitle {
  font-weight: bold;
  line-height: 32px;
}
.itemInput input {
  height: 45px;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  padding: 0 12px;
  font-size: 14px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #989898;
}
.comprehensive {
  border: 1px solid #aeaeae;
  padding: 4px;
  margin-top: 18px;
  .comprehensive-item {
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    border: 1px solid #707070;
    line-height: 50px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #636363;
    cursor: pointer;
    overflow: hidden;
  }
}
.Subject-or {
  margin-top: 10px;
  border: 1px solid #d6d6d6;
  opacity: 1;
  border-radius: 6px;
  dl {
    dd {
      .Subject-orDdTitle {
        padding: 20px 14px;
        label {
          cursor: pointer;
          display: block;
          position: relative;
          input {
            float: left;
            margin-top: 6px;
          }
          div {
            padding-left: 35px;
            padding-right: 35px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #060606;
            opacity: 1;
          }
        }
        i {
          position: absolute;
          right: 0px;
          top: 5px;
        }
      }
      .Subject-info {
        background: #e8e8e8;
        opacity: 1;
        border-radius: 0px;
        padding: 20px 20px 20px 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
        opacity: 1;
      }
    }
  }
}

.outlineBut {
  //padding: 30px 0;
  padding: 10px 0 0 0;
  text-align: center;
  button {
    height: 44px;

    opacity: 1;
    border-radius: 8px;
    padding: 0 20px;
    margin: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
    }
  }
}
.closeBut {
  float: right;
  text-align: center;
  button {
    height: 24px;
    opacity: 1;
    border-radius: 8px;
    padding: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
    }
  }
}

.Subject-If {
  height: 68px;
  background: #dfe9df;
  border: 1px solid #307a35;
  border-radius: 6px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #060606;
  opacity: 1;
  padding: 0 14px;
  margin-top: 20px;
  i {
    float: left;
    margin-top: 20px;
  }
  span {
    float: left;
    margin-left: 18px;
    margin-top: 22px;
  }
}
.br6 {
  border-radius: 6px;
  overflow: hidden;
  text-align: center;
  padding: 0;
  background: #ffffff;
  .br6_header {
    height: 50px;
    background: #e8e8e8;
    border-bottom: 1px solid #aeaeae;
    text-align: center;
    line-height: 50px;
  }
}
.h300BK_top {
  display: block;
  //min-height: 214px;
  height: 49px;
  .w25bf {
    width: 25%;
    display: block;
    float: left;
    padding: 4px;
  }
  .sortable-chosen {
    width: 25%;
    left: auto;
    top: auto;
  }
}
.h300BK {
  display: block;
  //min-height: 210px;
  height: 49px;
  position: relative;
  margin: 4px;
  cursor: pointer;
  &:hover {
    background-color: #dddddd;
    //border-radius: 6px;
  }

  border: 1px dashed rgb(112, 112, 112);
  border-radius: 6px;
  .w25bf {
    margin: -1px;
    padding: 0px;
    cursor: pointer;
  }
}
.Subject-explain {
  background: #ffffff;
  border: 1px solid #aeaeae;
  opacity: 1;
  border-radius: 6px;
  padding: 15px 20px;
  margin-top: 20px;
  .explainTitle {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    padding-left: 30px;
    background: url("~@/assets/outline/iconDown.png") no-repeat left;
    background-size: 16px 17px;
    line-height: 36px;
  }
  .explainCenter {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    line-height: 24px;
    margin-top: 20px;
  }
  &.on {
    background: #ede2e3;
    border: 1px solid #8d0005;
    opacity: 1;
    border-radius: 6px;
    .explainTitle {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      background: url("~@/assets/outline/naviconitem-3.png") no-repeat left;
    }
    .wrongReason {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      margin-top: 20px;
      padding-left: 30px;
    }
    .explainCenter {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #060606;
      opacity: 1;
      padding-left: 30px;
      a:link,
      a:visited {
        color: #8d0005;
      }
    }
  }
}

.Subject-bor {
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #aeaeae;
  margin-top: 20px;
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #636363;
  .Subject-pl36 {
    padding-left: 30px;
    background: url("~@/assets/WrongQuestion/iconsx1.png") no-repeat left;
  }
  .Subject-borMt15 {
    margin-top: 15px;
    padding-left: 30px;
  }
}
</style>
<style>
.itemSpan p,
.inWor p {
  display: inline;
  white-space: pre-line;
}
.SorDdTitle .el-radio__input,
.SorDdTitle .el-checkbox__input {
  float: left;
  margin-top: 2px;
}
.SorDdTitle .el-radio__label,
.SorDdTitle .el-checkbox__label {
  display: block;
  padding-left: 20px;
  line-height: 20px;
}
</style>
<style lang="scss" scoped>
.TitleRightItem1 {
  background: #dfe9df;
  border-radius: 6px;
  border: 1px solid #b7a114;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/outline/naviconitem-2.png") no-repeat center;
    background-size: cover;
  }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.TitleErrorItem1 {
  background: #ede2e3;
  border-radius: 6px;
  border: 1px solid #8d0005;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/outline/naviconitem-3.png") no-repeat center;
    background-size: cover;
  }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.TitleReportItem1 {
  background: #fffcea;
  border-radius: 6px;
  border: 1px solid #307a35;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/TrueTopic/gdhao.png") no-repeat center;
    background-size: cover;
  }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.w50bf-item-show {
  max-height: 350px;
  overflow: auto;
  .w50bfContent {
    width: 50%;
    float: left;
    padding: 5px;
    box-sizing: border-box;
    .w50bf-item {
      box-sizing: border-box;
      height: 49px;
      background: #ffffff;
      box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      border: 1px solid #707070;
      line-height: 49px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #636363;
      cursor: pointer;
      overflow: hidden;
    }
  }
  &::after {
    clear: both;
    display: block;
    content: "";
  }
}
</style>
