<template>
  <div
    style="
      border: 1px solid #d6d6d6;
      opacity: 1;
      border-radius: 6px;
      margin-top: 20px;
    "
  >
    <div v-show="arrowType" class="Subject-title">
      <div v-if="isShowSubjecTitle" class="Subject-title-fl">
        <!-- topicIndex + 1 -->
        第{{ topicVOIndex + 1 }}题<span
          v-if="topicData.typeAlias && isShowQuestionTitle"
          >{{ topicData.typeAlias }}</span
        ><span v-else-if="isShowQuestionTitle">
          (<span v-if="topicData.type == 'MULTIPLE_CHOICE'">多选题</span>
          <span v-if="topicData.type == 'TRUE_OR_FALSE'">判断题</span>
          <span v-if="topicData.type == 'SINGLE_CHOICE'">单选题</span>
          <span v-if="topicData.type == 'ESSAY_QUESTION'">问答题</span>
          <span v-if="topicData.type == 'GAP_FILLING'">填空题</span>
          <span v-if="topicData.type == 'MATCHING_ITEM'">匹配题</span>
          <span v-if="topicData.type == 'ACCOUNTING'">会计分录题</span>
          <span v-if="topicData.type == 'COMPREHENSIVE'">综合题</span>)</span
        >
      </div>
      <div v-if="arrowType == 'favorite'">
        <div
          v-if="isShowFavorite == true && isFavorite == false"
          class="Subject-title-fr"
          style="background: #173d7c"
          @click="handleFavorite('add')"
        >
          <i><img src="~@/assets/TrueTopic/iconjiao.png" /></i>
          <span>加入收藏夹</span>
        </div>
        <div
          v-if="isShowFavorite == true && isFavorite == true"
          class="Subject-title-fr"
          @click="handleFavorite('remove')"
        >
          <i><img src="~@/assets/TrueTopic/iconjiao.png" /></i>
          <span>取消收藏夹</span>
        </div>
      </div>
      <div v-else-if="arrowType == 'cancelWrong'">
        <div class="Subject-title-fr" @click="handleWrongTopic('remove')">
          <i><img src="~@/assets/TrueTopic/iconjiao.png" /></i>
          <span>移出错题集</span>
        </div>
      </div>
    </div>
    <!--<div style="width: 100%; height: 20px"></div>
    <el-divider style="margin-top: 3px"></el-divider>-->
    <div v-if="topicData.type == 'MULTIPLE_CHOICE'">
      <multiple-choice
        :questionData="topicData.questions[0]"
        :presentation="presentation"
        :isAnswerSubmit="isAnswerSubmit"
        :topicIndex="topicIndex"
        :isShowSubjecTitle="isShowSubjecTitle"
        :isShowFavorite="isShowFavorite"
        :refKind="refKind"
        :refId="refId"
        :isTest="isTest"
        ref="multipleChoice"
        @set-answers="setAnswers"
      />
    </div>
    <!--判断题-->
    <div v-if="topicData.type == 'TRUE_OR_FALSE'">
      <true-or-false
        :questionData="topicData.questions[0]"
        :presentation="presentation"
        :isAnswerSubmit="isAnswerSubmit"
        :topicIndex="topicIndex"
        :isShowSubjecTitle="isShowSubjecTitle"
        :isShowFavorite="isShowFavorite"
        :refKind="refKind"
        :refId="refId"
        :isTest="isTest"
        ref="trueOrFalse"
        @set-answers="setAnswers"
      />
    </div>
    <!--单选题-->
    <div v-if="topicData.type == 'SINGLE_CHOICE'">
      <single-choice
        :questionData="topicData.questions[0]"
        :presentation="presentation"
        :isAnswerSubmit="isAnswerSubmit"
        :topicIndex="topicIndex"
        :isShowSubjecTitle="isShowSubjecTitle"
        :isShowFavorite="isShowFavorite"
        :refKind="refKind"
        :refId="refId"
        :isTest="isTest"
        ref="singleChoice"
        @set-answers="setAnswers"
      />
    </div>
    <!--问答题-->
    <div v-if="topicData.type == 'ESSAY_QUESTION'">
      <essay-question
        :questionData="topicData.questions[0]"
        :presentation="presentation"
        :isAnswerSubmit="isAnswerSubmit"
        :topicIndex="topicIndex"
        :isShowSubjecTitle="isShowSubjecTitle"
        :isShowFavorite="isShowFavorite"
        :refKind="refKind"
        :refId="refId"
        :isTest="isTest"
        ref="essayQuestion"
        @set-answers="setAnswers"
      />
    </div>
    <!--填空题-->
    <div v-if="topicData.type == 'GAP_FILLING'">
      <gap-filling
        :questionData="topicData.questions[0]"
        :presentation="presentation"
        :isAnswerSubmit="isAnswerSubmit"
        :topicIndex="topicIndex"
        :isShowSubjecTitle="isShowSubjecTitle"
        :isShowFavorite="isShowFavorite"
        :refKind="refKind"
        :refId="refId"
        :isTest="isTest"
        ref="gapFilling"
        @set-answers="setAnswers"
      />
    </div>
    <div v-if="topicData.type == 'MATCHING_ITEM'">
      <matching-item
        :questionData="topicData.questions[0]"
        :presentation="presentation"
        :isAnswerSubmit="isAnswerSubmit"
        :topicIndex="topicIndex"
        :isShowSubjecTitle="isShowSubjecTitle"
        :isShowFavorite="isShowFavorite"
        :refKind="refKind"
        :refId="refId"
        :isTest="isTest"
        ref="matchingItem"
        @set-answers="setAnswers"
      />
    </div>
    <!--会计分录题-->
    <div v-if="topicData.type == 'ACCOUNTING'">
      <accounting
        :questionData="topicData.questions[0]"
        :presentation="presentation"
        :isAnswerSubmit="isAnswerSubmit"
        :topicIndex="topicIndex"
        :isShowSubjecTitle="isShowSubjecTitle"
        :isShowFavorite="isShowFavorite"
        :refKind="refKind"
        :refId="refId"
        :isTest="isTest"
        ref="accounting"
        @set-answers="setAnswers"
      />
    </div>
    <div v-if="topicData.type == 'COMPREHENSIVE'">
      <comprehensive
        :topicData="topicData"
        :presentation="presentation"
        :isAnswerSubmit="isAnswerSubmit"
        :topicIndex="topicIndex"
        :isShowSubjecTitle="isShowSubjecTitle"
        :isShowFavorite="isShowFavorite"
        :refKind="refKind"
        :refId="refId"
        :isTest="isTest"
        ref="comprehensive"
        @set-answers="setAnswers"
      />
    </div>
    <div
      v-show="isAnswerSubmit && topicData.type == 'COMPREHENSIVE'"
      style="padding: 10px"
    >
      <div class="TitleReport1Center-mainItem">
        <div>
          <img src="~@/assets/TrueTopic/iconc-2.png" title="正确率" />
          <!---style="color: #8d0005"-->
          <span> {{ scoringRate }} %</span>
        </div>
        <div>
          <img src="~@/assets/TrueTopic/iconc-2.png" title="正确数" />
          <span>{{ correctCount }}</span>
        </div>
        <div>
          <img src="~@/assets/TrueTopic/iconc-3.png" title="错误数" />
          <span>{{ errorCount }}</span>
        </div>
        <!--<div>
          <img src="~@/assets/TrueTopic/iconc-2.png" title="得分数" />
          <span>{{ this.topicAnswers.scores }}</span>
        </div>-->
        <!-- <div>
                  <img src="~@/assets/TrueTopic/iconc-4.png" />
                  <span>{{ topicResultData.statVo.partCount }}</span>
                </div> -->
      </div>
    </div>
    <div
      class="outlineBut"
      v-if="presentation == 'ONE_BY_ONE' && !isAnswerSubmit"
    >
      <button class="but2" type="button" @click="answerSubmit">提交</button>
    </div>
    <div
      v-else-if="presentation == 'ONE_BY_ONE' && isAnswerSubmit"
      class="outlineBut"
    >
      <button class="but2" type="button" @click="resetAnswer">重做</button>
    </div>
    <topic-explain v-if="isAnswerSubmit" :topicData="topicData" />
    <topic-rec v-if="isAnswerSubmit" :topicData="topicData" />
  </div>
</template>
<script>
import MultipleChoice from "@/components/Question/MultipleChoice.vue";
import SingleChoice from "@/components/Question/SingleChoice.vue";
import TrueOrFalse from "@/components/Question/TrueOrFalse.vue";
import EssayQuestion from "@/components/Question/EssayQuestion.vue";
import GapFilling from "@/components/Question/GapFilling.vue";
import Accounting from "@/components/Question/Accounting.vue";
import Comprehensive from "@/components/Question/Comprehensive.vue";
import MatchingItem from "@/components/Question/MatchingItem.vue";
import TopicExplain from "@/components/Question/TopicExplain.vue";
import TopicRec from "@/components/TopicResult/TopicRec.vue";
import { addFavorite, removeFavorite } from "@/utils/favorite";
import { deleteWrongTopic } from "@/api/memberWrongTopicsController";
import {
  coldcallTopicResultSubmit,
  examTopicResultSubmit,
  practiceTopicResultSubmit,
  quizTopicResultSubmit,
} from "@/api/memberTopicResultController";
export default {
  name: "Topic",
  props: {
    topicData: {
      type: Object,
      default: null,
    },
    presentation: {
      type: String,
      default: null,
    },
    isShowSubjecTitle: {
      type: Boolean,
      default: true,
    },
    isAnswerSubmit: {
      type: Boolean,
      default: false,
    },
    isShowFavorite: {
      //是否显示收藏操作
      type: Boolean,
      default: true,
    },
    isShowQuestionTitle: {
      type: Boolean,
      default: true,
    },
    topicIndex: {
      type: Number,
      default: 0,
    },
    topicVOIndex: {
      type: Number,
      default: 0,
    },
    refKind: {
      type: String,
      default: null,
    },
    refId: {
      type: Number,
      default: -1,
    },
    wrongTopicRefKind: {
      type: String,
      default: null,
    },
    wrongTopicRefId: {
      type: Number,
      default: -1,
    },
    segmentId: {
      type: Number,
      default: -1,
    },
    chapterId: {
      type: Number,
      default: -1,
    },
    sectionId: {
      type: Number,
      default: -1,
    },
    practiceIndex: {
      type: Number,
      default: -1,
    },
    arrowType: {
      type: String,
      default: null,
    },
    isTest: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShowComment: false,
      isFavorite: false,
      topicAnswers: {
        answers: [],
        // refId: 0,
        // refKind: "",
        scores: 0,
        spend: 0,
        topicId: 0,
      },
      scoringRate: 0,
      correctCount: 0,
      errorCount: 0,
    };
  },
  components: {
    MultipleChoice,
    SingleChoice,
    TrueOrFalse,
    TopicExplain,
    EssayQuestion,
    GapFilling,
    Accounting,
    Comprehensive,
    MatchingItem,
    TopicRec,
  },
  created() {
    this.isFavorite = this.isFavoriteTopics(this.topicData.id);
    // this.topicAnswers.refId = this.refId;
    // this.topicAnswers.refKind = this.refKind;
    // const id = sessionStorage.getItem("COURSE_ID");
    this.topicAnswers.topicId = this.topicData.id;
    //this.topicAnswers.courseId = id;
    let questionsLength = this.topicData.questions.length;
    for (let i = 0; i < questionsLength; i++) {
      this.topicAnswers.answers.push({});
    }
  },
  methods: {
    getCourseId() {
      let id = null;
      const key = "COURSE_ID";
      if (this.$cookies.isKey(key)) {
        id = this.$cookies.get(key);
      }
      return id;
    },
    resetAnswer() {
      this.isAnswerSubmit = false;
      if (this.topicData.type == "SINGLE_CHOICE") {
        this.$refs.singleChoice.initAnswer();
      } else if (this.topicData.type == "MULTIPLE_CHOICE") {
        this.$refs.multipleChoice.initAnswer();
      } else if (this.topicData.type == "TRUE_OR_FALSE") {
        this.$refs.trueOrFalse.initAnswer();
      } else if (this.topicData.type == "ESSAY_QUESTION") {
        this.$refs.essayQuestion.initAnswer();
      } else if (this.topicData.type == "GAP_FILLING") {
        this.$refs.gapFilling.initAnswer();
      } else if (this.topicData.type == "MATCHING_ITEM") {
        this.$refs.matchingItem.initAnswer();
      } else if (this.topicData.type == "ACCOUNTING") {
        this.$refs.accounting.initAnswer();
      } else if (this.topicData.type == "COMPREHENSIVE") {
        this.$refs.comprehensive.initAnswer();
      }
    },
    answerSubmit() {
      this.isAnswerSubmit = true;
      const id = this.getCourseId(); //sessionStorage.getItem("COURSE_ID");
      let answerReport = {
        refId: this.refId,
        refKind: this.refKind,
        courseId: id,
        scores: this.topicAnswers.scores,
        spend: 0,
        saveResult: true,
        topicAnswers: [this.topicAnswers],
        segmentId: this.segmentId == -1 ? null : this.segmentId,
        wrongTopicRefKind: this.wrongTopicRefKind,
        wrongTopicRefId: this.wrongTopicRefId,
        chapterId: this.chapterId == -1 ? null : this.chapterId,
        sectionId: this.sectionId == -1 ? null : this.sectionId,
      };
      //Practice|Exam|ColdCall|Quiz
      if (this.refKind == "Practice") {
        practiceTopicResultSubmit(answerReport).then((res) => {
          if (res.code == 20000) {
            this.$baseMessage("提交成功", "success", "vab-hey-message-success");
            this.$emit("refresh-tree", this.practiceIndex, this.topicIndex);
          } else {
            this.$baseMessage(res.msg, "error", "vab-hey-message-error");
            //this.isAnswerSubmit = false;
          }
        });
      } else if (this.refKind == "Exam") {
        examTopicResultSubmit(answerReport).then((res) => {
          if (res.code == 20000) {
            this.$baseMessage("提交成功", "success", "vab-hey-message-success");
            this.$emit("refresh-tree");
          } else {
            this.$baseMessage(res.msg, "error", "vab-hey-message-error");
            this.isAnswerSubmit = false;
          }
        });
      } else if (this.refKind == "ColdCall") {
        coldcallTopicResultSubmit(answerReport).then((res) => {
          if (res.code == 20000) {
            this.$baseMessage("提交成功", "success", "vab-hey-message-success");
          } else {
            this.$baseMessage(res.msg, "error", "vab-hey-message-error");
            this.isAnswerSubmit = false;
          }
        });
      } else if (this.refKind == "Quiz") {
        quizTopicResultSubmit(answerReport).then((res) => {
          if (res.code == 20000) {
            this.$baseMessage("提交成功", "success", "vab-hey-message-success");
            this.$emit("refresh-tree");
          } else {
            this.$baseMessage(res.msg, "error", "vab-hey-message-error");
            this.isAnswerSubmit = false;
          }
        });
      }
    },
    async handleFavorite(type) {
      if (type === "add") {
        await addFavorite(this.topicData.id, {
          segmentId: this.segmentId == -1 ? null : this.segmentId,
          refKind: this.wrongTopicRefKind,
          refId: this.wrongTopicRefId,
          chapterId: this.chapterId == -1 ? null : this.chapterId,
          sectionId: this.sectionId == -1 ? null : this.sectionId,
        });
        this.isFavorite = this.isFavoriteTopics(this.topicData.id);
      } else if (type === "remove") {
        await removeFavorite(this.topicData.id);
        this.isFavorite = this.isFavoriteTopics(this.topicData.id);
        this.$emit("set-favorite-callback");
      }
    },
    async handleWrongTopic() {
      await deleteWrongTopic(this.topicData.id).then((res) => {
        if (res.code != 20000) {
          this.$baseMessage(res.msg, "error", "vab-hey-message-error");
        } else {
          this.$baseMessage("移除成功", "success", "vab-hey-message-success");
          this.$emit("set-wrong-topic-callback");
        }
      });
    },
    setAnswers(questionIndex, answer) {
      this.topicAnswers.answers[questionIndex] = answer;
      this.topicAnswers.scores = 0; //answer.scores;
      this.scoringRate = 0;
      let answersLength = this.topicAnswers.answers.length;
      for (let i = 0; i < answersLength; i++) {
        let answer = this.topicAnswers.answers[i];
        this.topicAnswers.scores += answer.scores;
        if (typeof answer.scoringRate != "undefined") {
          this.scoringRate += answer.scoringRate;
        }
        if (answer.ret == "CORRECT") {
          this.correctCount++;
        }
      }
      this.errorCount = answersLength - this.correctCount;
      this.scoringRate = this.scoringRate / answersLength;
      if (
        this.scoringRate.toString().indexOf(".") != -1 &&
        (this.scoringRate + "").split(".")[1].length > 2
      ) {
        this.scoringRate = this.scoringRate.toFixed(2);
      }
      this.$emit(
        "set-topic-answers",
        this.practiceIndex,
        this.topicIndex,
        this.topicAnswers
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.Subject-title {
  padding: 10px 10px 11px 10px;
  height: 51px;
  border-bottom: 1px solid #d6d6d6;
  .Subject-title-fl {
    font-size: 28px;
    font-weight: bold;
    color: #575b67;
    float: left;
    line-height: 30px;
    span {
      font-size: 24px;
    }
  }
  .Subject-title-fr {
    float: right;
    height: 30px;
    background: #636363;
    line-height: 30px;
    border-radius: 16px;
    padding: 0 10px;
    margin-top: 1px;
    i {
      margin-top: 2px;
      float: left;
    }
    span {
      color: #ffffff;
      font-size: 12px;
      font-weight: normal;
      margin-left: 5px;
      float: left;
    }
  }
}
.outlineBut {
  //padding: 10px 0;
  margin-bottom: 10px;
  //margin-top: 10px;
  text-align: center;
  button {
    height: 44px;
    opacity: 1;
    border-radius: 8px;
    padding: 0 20px;
    //margin: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
    }
  }
}
.TitleReport1Center-mainItem {
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #707070;
  margin-top: 10px;
  border-bottom: 1px solid #707070;
  div {
    float: left;
    width:  33.33333%;
    display: block;
    box-sizing: border-box;
    text-align: center;
    border-left: 1px solid #707070;
    line-height: 215px;
    font-size: 0;
    height: 215px;
    overflow: hidden;
    img {
      display: inline-block;
    }
    span {
      font-size: 60px;
      font-weight: bold;
      color: #7b7b7b;
      display: inline-block;
      margin-left: 10px;
    }
    &:first-child {
      margin-left: 0;
      border-left: 0;
    }
  }
  &::after {
    clear: both;
    display: block;
    content: "";
  }
}
</style>
