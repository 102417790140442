import request from "@/utils/request";
const BASE_PATH = "/front/ground/wrong-topic/";

export function postWrongTopic(topicId) {
  return request({
    url: BASE_PATH + topicId,
    method: "POST",
  });
}

export function deleteWrongTopic(topicId) {
  return request({
    url: BASE_PATH + topicId,
    method: "delete",
  });
}

export function getWrongTopicList(query) {
  return request({
    url: BASE_PATH + "list",
    method: "get",
    params: query,
  });
}


