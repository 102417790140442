import request from "@/utils/request";

const BASE_PATH = "/front/material/media-file";
export function getMediaFileInfo(query) {
  return request({
    url: BASE_PATH,
    method: "get",
    params: query,
  });
}
