var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.questionData != null)?_c('div',{staticStyle:{"padding":"10px"}},[_c('div',{staticClass:"Subject-topicAsk"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.questionData.content)}}),_c('el-tooltip',{staticClass:"item",staticStyle:{"float":"right"},attrs:{"effect":"dark","content":"以下是匹配题的选项，请将下列选项拖到下方表格中的适当位置。","placement":"top-end"}},[_c('i',{staticClass:"el-icon-question"})])],1),_c('el-row',{staticClass:"comprehensive"},[_c('draggable',{attrs:{"group":"people","itemKey":"itemId"},model:{value:(_vm.matcheOptions),callback:function ($$v) {_vm.matcheOptions=$$v},expression:"matcheOptions"}},[_c('transition-group',{staticClass:"h300BK_top"},_vm._l((_vm.matcheOptions),function(matcheOptionItem){return _c('div',{key:matcheOptionItem.itemId,staticClass:"w25bf"},[_c('div',{staticClass:"comprehensive-item",attrs:{"title":matcheOptionItem.itemValue}},[_vm._v(" "+_vm._s(matcheOptionItem.itemValue)+" ")])])}),0)],1)],1),_vm._l((_vm.answerRow),function(row){return _c('div',{key:row,staticClass:"comprehensive br6",attrs:{"type":"flex","align-items":"stretch"}},[_c('el-row',{staticStyle:{"width":"100%"}},[_c('el-col',{staticClass:"br6_header"},[_vm._v(" "+_vm._s(_vm.questionMatches[row - 1].match)+" ")])],1),_vm._l((Math.ceil(
        _vm.questionMatches[row - 1].subItems.length / 3
      )),function(subRow){return _c('el-row',{key:subRow,staticStyle:{"width":"100%"}},_vm._l((subRow * 3 > _vm.questionMatches[row - 1].subItems.length
          ? _vm.questionMatches[row - 1].subItems.length - (subRow - 1) * 3
          : 3),function(cell){return _c('el-col',{key:cell,attrs:{"span":8}},[(_vm.matcheOptions.length > 0)?_c('el-popover',{attrs:{"placement":"right","width":"400","trigger":"click"},model:{value:(
            _vm.questionMatches[row - 1].subItems[(subRow - 1) * 3 + cell - 1]
              .nameVisible
          ),callback:function ($$v) {_vm.$set(_vm.questionMatches[row - 1].subItems[(subRow - 1) * 3 + cell - 1]
              , "nameVisible", $$v)},expression:"\n            questionMatches[row - 1].subItems[(subRow - 1) * 3 + cell - 1]\n              .nameVisible\n          "}},[_c('div',{staticClass:"w50bf-item-show"},_vm._l((_vm.matcheOptions),function(itemEntrie,i){return _c('div',{key:i,staticClass:"w50bfContent"},[_c('div',{staticClass:"w50bf-item",attrs:{"title":itemEntrie.itemValue},on:{"click":function($event){_vm.addItemFn(
                    itemEntrie,
                    _vm.questionMatches[row - 1].subItems[
                      (subRow - 1) * 3 + cell - 1
                    ],
                    _vm.matcheOptions
                  )}}},[_vm._v(" "+_vm._s(itemEntrie.itemValue)+" ")])])}),0),_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('draggable',{staticStyle:{"padding":"4px"},attrs:{"group":"people","itemKey":"itemId"},on:{"add":function($event){_vm.addDraggable(
                  _vm.questionMatches[row - 1].subItems[
                    (subRow - 1) * 3 + cell - 1
                  ].answer
                )}},model:{value:(
                _vm.questionMatches[row - 1].subItems[(subRow - 1) * 3 + cell - 1]
                  .answer
              ),callback:function ($$v) {_vm.$set(_vm.questionMatches[row - 1].subItems[(subRow - 1) * 3 + cell - 1]
                  , "answer", $$v)},expression:"\n                questionMatches[row - 1].subItems[(subRow - 1) * 3 + cell - 1]\n                  .answer\n              "}},[_c('transition-group',{staticClass:"h300BK"},_vm._l((_vm.questionMatches[row - 1].subItems[
                    (subRow - 1) * 3 + cell - 1
                  ].answer),function(answerItem){return _c('div',{key:answerItem.itemId,staticClass:"w25bf"},[_c('div',{staticClass:"comprehensive-item",attrs:{"title":answerItem.itemValue}},[_vm._v(" "+_vm._s(answerItem.itemValue)+" ")])])}),0)],1)],1)]):_c('draggable',{staticStyle:{"padding":"4px"},attrs:{"group":"people","itemKey":"itemId"},on:{"add":function($event){_vm.addDraggable(
              _vm.questionMatches[row - 1].subItems[(subRow - 1) * 3 + cell - 1]
                .answer
            )}},model:{value:(
            _vm.questionMatches[row - 1].subItems[(subRow - 1) * 3 + cell - 1]
              .answer
          ),callback:function ($$v) {_vm.$set(_vm.questionMatches[row - 1].subItems[(subRow - 1) * 3 + cell - 1]
              , "answer", $$v)},expression:"\n            questionMatches[row - 1].subItems[(subRow - 1) * 3 + cell - 1]\n              .answer\n          "}},[_c('transition-group',{staticClass:"h300BK"},_vm._l((_vm.questionMatches[row - 1].subItems[
                (subRow - 1) * 3 + cell - 1
              ].answer),function(answerItem){return _c('div',{key:answerItem.itemId,staticClass:"w25bf"},[_c('div',{staticClass:"comprehensive-item",attrs:{"title":answerItem.itemValue}},[_vm._v(" "+_vm._s(answerItem.itemValue)+" ")])])}),0)],1)],1)}),1)})],2)}),(_vm.topicType != null && !_vm.isCheck && !_vm.isTest)?_c('div',{staticClass:"outlineBut"},[_c('button',{staticClass:"but2",attrs:{"type":"button"},on:{"click":_vm.check}},[_vm._v("核对")])]):_vm._e(),(_vm.isAnswerSubmit || _vm.isCheck)?_c('div',{class:_vm.getAnswerStyle(_vm.questionAnswer.ret),staticStyle:{"margin-top":"20px"}},[_c('i'),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.getAnswerTip(_vm.questionAnswer.ret)))]),_c('span',{staticStyle:{"margin-left":"50px"}},[(_vm.topicType == 'COMPREHENSIVE')?_c('span',[_vm._v("第"+_vm._s(_vm.questionIndex + 1)+"小")]):_c('span',[_vm._v("本")]),_vm._v("题得分率为："),_c('span',{staticStyle:{"font-weight":"800"}},[_vm._v(_vm._s(_vm.scoringRate)+"%")])]),(_vm.topicType != null && _vm.isCheck && !_vm.isAnswerSubmit)?_c('div',{staticClass:"closeBut"},[_c('button',{staticClass:"but2",attrs:{"type":"button"},on:{"click":function($event){_vm.isCheck = false}}},[_vm._v(" 关闭 ")])]):_vm._e(),_c('div',{staticClass:"Subject-bor"},[_c('div',{staticClass:"Subject-pl36"},[_vm._v("答案:")]),_c('div',{staticClass:"Subject-borMt15"},_vm._l((_vm.answerRow),function(row){return _c('div',{key:row,staticClass:"comprehensive br6",attrs:{"type":"flex","align-items":"stretch"}},[_c('el-row',{staticStyle:{"width":"100%"}},[_c('el-col',{staticClass:"br6_header"},[_vm._v(" "+_vm._s(_vm.questionMatches[row - 1].match)+" ")])],1),_vm._l((Math.ceil(
              _vm.questionMatches[row - 1].subItems.length / 3
            )),function(subRow){return _c('el-row',{key:subRow,staticStyle:{"width":"100%"},attrs:{"span":24}},_vm._l((subRow * 3 >
              _vm.questionMatches[row - 1].subItems.length
                ? _vm.questionMatches[row - 1].subItems.length - (subRow - 1) * 3
                : 3),function(cell){return _c('el-col',{key:cell,attrs:{"span":8}},[(
                  _vm.questionMatches[row - 1].subItems[
                    (subRow - 1) * 3 + cell - 1
                  ].id != -1
                )?_c('div',{staticClass:"w25bf",staticStyle:{"padding":"4px"}},[_c('div',{staticClass:"comprehensive-item",attrs:{"title":_vm.questionMatches[row - 1].subItems[
                      (subRow - 1) * 3 + cell - 1
                    ].itemValue}},[_vm._v(" "+_vm._s(_vm.questionMatches[row - 1].subItems[ (subRow - 1) * 3 + cell - 1 ].itemValue)+" ")])]):_vm._e()])}),1)})],2)}),0),(_vm.isRichTextNotNull(_vm.questionData.comment))?_c('div',{staticClass:"Subject-pl36",staticStyle:{"margin-top":"20px"}},[_vm._v(" 注解: ")]):_vm._e(),(_vm.isRichTextNotNull(_vm.questionData.comment))?_c('div',{staticClass:"Subject-borMt15"},[_c('div',{staticClass:"explainCenter",domProps:{"innerHTML":_vm._s(_vm.questionData.comment)}})]):_vm._e()])]):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }