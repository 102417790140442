<template>
<!-- margin-top: -15px -->
  <div v-if="questionData != null" style="padding: 10px">
    <div class="Subject-topicAsk" v-html="questionData.content"></div>
    <div class="Subject-or-text">
      <textarea v-model="answer"></textarea>
    </div>
    <div class="outlineBut" v-if="topicType != null && !isCheck && !isTest">
      <button class="but2" type="button" @click="check">核对</button>
    </div>
    <div
      v-if="
        (isAnswerSubmit || isCheck) &&
        isRichTextNotNull(questionData.fields[0].option)
      "
    >
      <!-- <div class="closeBut" v-if="topicType != null && isCheck">
        <button class="but2" type="button" @click="isCheck = false">
          关闭
        </button>
      </div> -->
      <div
        class="Subject-bor"
        v-for="(itemField, fieldIndex) in questionData.fields"
        :key="fieldIndex"
      >
        <div
          class="Subject-pl36"
          v-if="itemField.option != ''"
          @click="isCheck = false"
        >
          参考答案
        </div>
        <div class="Subject-borMt15" v-html="itemField.option"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { isRichTextNotNull } from "@/utils/validate";
export default {
  name: "EssayQuestion",
  components: {},
  props: {
    questionData: {
      type: Object,
      default: null,
    },
    presentation: {
      type: String,
      default: null,
    },
    isShowSubjecTitle: {
      type: Boolean,
      default: true,
    },
    isAnswerSubmit: {
      type: Boolean,
      default: false,
    },
    isShowFavorite: {
      //是否显示收藏操作
      type: Boolean,
      default: true,
    },
    refKind: {
      type: String,
      default: null,
    },
    refId: {
      type: Number,
      default: -1,
    },
    topicIndex: {
      type: Number,
      default: 0,
    },
    questionIndex: {
      type: Number,
      default: 0,
    },
    topicType: {
      type: String,
      default: null,
    },
    isTest: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isAnswerSubmit: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        if (val) {
          this.isCheck = true;
        }
      },
    },
    answer: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        if (this.answer == "") {
          this.questionAnswer.scores = 0;
          this.questionAnswer.ret = "BLANK";
          this.questionAnswer.scoringRate = 0;
        } else {
          this.questionAnswer.ret = "CORRECT";
          this.questionAnswer.scores = this.questionData.score;
          this.questionAnswer.scoringRate = 100;
        }
        this.questionAnswer.fieldsAnswers = [];
        this.questionAnswer.fieldsAnswers.push({
          itemId: "-1",
          itemLabel: "",
          itemValue: this.answer,
        });
        this.$emit("set-answers", this.questionIndex, this.questionAnswer);
      },
      deep: true,
    },
  },
  data() {
    return {
      answer: null,
      isCheck: false,
      questionAnswer: {
        approved: true,
        fieldsAnswers: [],
        questionId: 0,
        ret: "BLANK",
        scores: 0,
        weight: 0,
      },
    };
  },
  created() {
    this.questionAnswer.questionId = this.questionData.id;
    this.answer = "";
  },
  methods: {
    initAnswer() {
      this.answer = "";
      this.isCheck = false;
    },
    isRichTextNotNull(comment) {
      return isRichTextNotNull(comment);
    },
    answerSubmit() {
      this.isAnswerSubmit = true;
    },
    check() {
      this.isCheck = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.Subject-title {
  height: 30px;
  margin-top: 20px;
  .Subject-title-fl {
    font-size: 28px;
    font-weight: bold;
    color: #575b67;
    float: left;
    line-height: 30px;
  }
  .Subject-title-fr {
    float: right;
    height: 30px;
    background: #636363;
    line-height: 30px;
    border-radius: 16px;
    padding: 0 10px;
    i {
      margin-top: 3px;
      float: left;
    }
    span {
      color: #ffffff;
      font-size: 12px;
      font-weight: normal;
      margin-left: 5px;
      float: left;
    }
  }
}
.Subject-or-title {
  line-height: 30px;
  padding: 20px 0 5px 0;
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
.Subject-topicAsk {
  //margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #060606;
}
.Subject-or-text {
  padding-top: 10px;
  textarea {
    padding: 5px 10px;
    border: 1px solid #707070;
    height: 175px;
    line-height: 24px;
    font-size: 16px;
    width: 100%;
  }
}
.Subject-or {
  margin-top: 10px;
  border: 1px solid #d6d6d6;
  opacity: 1;
  border-radius: 6px;
  dl {
    dd {
      .Subject-orDdTitle {
        padding: 20px 14px;
        label {
          cursor: pointer;
          display: block;
          position: relative;
          input {
            float: left;
            margin-top: 6px;
          }
          div {
            padding-left: 35px;
            padding-right: 35px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #060606;
            opacity: 1;
          }
        }
        i {
          position: absolute;
          right: 0px;
          top: 5px;
        }
      }
      .Subject-info {
        background: #e8e8e8;
        opacity: 1;
        border-radius: 0px;
        padding: 20px 20px 20px 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
        opacity: 1;
      }
    }
  }
}

.outlineBut {
  //padding: 30px 0;
  padding: 10px 0 0 0;
  text-align: center;
  button {
    height: 44px;

    opacity: 1;
    border-radius: 8px;
    padding: 0 20px;
    margin: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
    }
  }
}

.closeBut {
  float: right;
  text-align: center;
  button {
    height: 24px;
    opacity: 1;
    border-radius: 8px;
    padding: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
    }
  }
}

.Subject-If {
  height: 68px;
  background: #dfe9df;
  border: 1px solid #307a35;
  border-radius: 6px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #060606;
  opacity: 1;
  padding: 0 14px;
  margin-top: 20px;
  i {
    float: left;
    margin-top: 20px;
  }
  span {
    float: left;
    margin-left: 18px;
    margin-top: 22px;
  }
}

.Subject-explain {
  background: #ffffff;
  border: 1px solid #aeaeae;
  opacity: 1;
  border-radius: 6px;
  padding: 15px 20px;
  margin-top: 20px;
  .explainTitle {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    padding-left: 30px;
    background: url("~@/assets/outline/iconDown.png") no-repeat left;
    background-size: 16px 17px;
    line-height: 36px;
  }
  .explainCenter {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    line-height: 24px;
    margin-top: 20px;
  }
  &.on {
    background: #ede2e3;
    border: 1px solid #8d0005;
    opacity: 1;
    border-radius: 6px;
    .explainTitle {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      background: url("~@/assets/outline/naviconitem-3.png") no-repeat left;
    }
    .wrongReason {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      margin-top: 20px;
      padding-left: 30px;
    }
    .explainCenter {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #060606;
      opacity: 1;
      padding-left: 30px;
      a:link,
      a:visited {
        color: #8d0005;
      }
    }
  }
}

.Subject-bor {
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #aeaeae;
  margin-top: 20px;
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #636363;
  .Subject-pl36 {
    padding-left: 30px;
    background: url("~@/assets/WrongQuestion/iconsx1.png") no-repeat left;
  }
  .Subject-borMt15 {
    margin-top: 15px;
    padding-left: 30px;
  }
}
</style>
<style>
.itemSpan p,
.inWor p {
  display: inline;
  white-space: pre-line;
}
.SorDdTitle .el-radio__input,
.SorDdTitle .el-checkbox__input {
  float: left;
  margin-top: 2px;
}
.SorDdTitle .el-radio__label,
.SorDdTitle .el-checkbox__label {
  display: block;
  padding-left: 20px;
  line-height: 20px;
}
</style>
