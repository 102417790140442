<template>
  <div
    v-if="
      topicData != null &&
      topicData.explain != null &&
      (topicData.explain.mediaType != '' ||
        isRichTextNotNull(topicData.explain.content))
    "
    style="padding: 10px"
  >
    <div id="test" class="Subject-bor">
      <div
        v-if="isRichTextNotNull(topicData.explain.title)"
        class="Subject-pl36"
      >
        {{ topicData.explain.title }}
      </div>
      <div
        v-if="isRichTextNotNull(topicData.explain.content)"
        class="Subject-borMt15"
        v-html="topicData.explain.content"
      ></div>
      <div
        id="videoPlayerDiv"
        ref="videoPlayerDiv"
        style="top: 3.571vw; z-index: 999; "
      >
        <video-player
          v-if="topicData.explain.mediaType == 'VIDEO'"
          id="videoPlayer"
          ref="videoPlayer"
          :videoId="'video-' + topicData.questions[0].id"
          @setDrag="setDrag"
          class="vjs-custom-skin"
          :options="playerOptions2"
          v-drag
          drag="false"
        />
      </div>
      <img
        v-if="topicData.explain.mediaType == 'IMAGE'"
        width="100%"
        style="margin-top: 20px"
        :src="topicData.explain.mediaFile"
      />
      <!--http://frontpage.siriux.cygnux.cn/upload/pdf/6b/cf15/d572/f9aa/abd12dee313558f19b.pdf 正式服务器要是不显示就加载前缀-->
      <iframe
        v-if="topicData.explain.mediaType == 'PDF'"
        style="margin-top: 20px"
        width="100%"
        height="700px"
        :src="topicData.explain.mediaFile"
      />
    </div>
  </div>
</template>
<script>
import { isRichTextNotNull } from "@/utils/validate";
// import "video.js/dist/video-js.css";
// import "@/components/VueVideoPlayer/custom-theme.css";
import videoPlayer from "@/components/VueVideoPlayer/player.vue";
import { getMediaFileInfo } from "@/api/materialController";
export default {
  name: "TopicExplain",
  components: {
    videoPlayer,
  },
  props: {
    topicData: {
      type: Object,
      default: null,
    },
  },
  watch: {
    topicData: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        this.play();
      },
      deep: true,
    },
  },
  data() {
    return {
      playerOptions2: {
        playbackRates: [0.7, 1.0, 1.25, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        controls: true, // 控制条
        preload: "auto", // 视频预加载
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: "", // 你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        isShowVideoDocBtn: false,
        isShowDragTip: false,
        isDrag: true,
        showDragTipDuration: 3000,
      },
      isValid: false,
      videoPlayer2Width: 0,
      videoPlayer2Height: 0,
      videoPlayer2Top: 0,
      videoPlayer2Left: 0,
    };
  },
  directives: {
    drag: {
      // 指令的定义
      bind: function (el) {
        let oDiv = el; // 获取当前元素
        oDiv.onmousedown = (e) => {
          console.log("onmousedown:" + oDiv.getAttribute("drag"));
          if (oDiv.getAttribute("drag") === "false") {
            return;
          }
          // 算出鼠标相对元素的位置
          let disX = e.clientX - oDiv.offsetLeft;
          let disY = e.clientY - oDiv.offsetTop;

          document.onmousemove = (e) => {
            // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            let left = e.clientX - disX;
            let top = e.clientY - disY;

            oDiv.style.left = left + "px";
            oDiv.style.top = top + "px";
          };

          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },
  created() {
    this.play();
    if (
      this.topicData != null &&
      this.topicData.explain != null &&
      this.topicData.explain.mediaType == "VIDEO"
    ) {
      //this.initVideo();
    }
  },
  methods: {
    initVideo() {
      var that = this;
      setTimeout(function () {
        let width = that.$refs.videoPlayerDiv.clientWidth;
        let height = that.$refs.videoPlayerDiv.clientHeight;
        let top = that.$refs.videoPlayerDiv.offsetTop;
        let left = that.$refs.videoPlayerDiv.offsetLeft;
        document.getElementById("videoPlayer").style.position = "absolute";
        document.getElementById("videoPlayer").style.left = left + "px";
        document.getElementById("videoPlayer").style.top = top + "px";
        document.getElementById("videoPlayer").style.width = width + "px";
        document.getElementById("videoPlayer").style.height = height + "px";
        that.videoPlayer2Width = width;
        that.videoPlayer2Height = height;
        that.videoPlayer2Top = top;
        that.videoPlayer2Left = left;
      }, 100);
    },
    setDrag() {
      var drag = this.$refs.videoPlayer.$el.getAttribute("drag");
      if (drag === "false") {
        this.$refs.videoPlayerDiv.style.position = "sticky";
        this.$refs.videoPlayer.$el.setAttribute("drag", "true");
      } else {
        this.$refs.videoPlayer.$el.setAttribute("drag", "false");
        this.$refs.videoPlayerDiv.style.position = "static";
        this.$refs.videoPlayer.$el.style.top = this.videoPlayer2Top + "px";
        this.$refs.videoPlayer.$el.style.left = this.videoPlayer2Left + "px";
      }
    },
    isRichTextNotNull(comment) {
      return isRichTextNotNull(comment);
    },
    play() {
      if (
        this.topicData != null &&
        this.topicData.explain.mediaType == "VIDEO"
      ) {
        getMediaFileInfo({ filePath: this.topicData.explain.mediaFile }).then(
          (res) => {
            if (res.code == 20000) {
              this.mediaFileData = res.data;
              if (res.data.deliveryUrl != "" && res.data.deliveryUrl != null) {
                this.playerOptions2.sources = [
                  {
                    type: "video/mp4",
                    src:
                      res.data.uri == null
                        ? this.topicData.explain.mediaFile
                        : res.data.uri,
                    fileID: res.data.deliveryUrl,
                  },
                ];
              } else {
                this.playerOptions2.sources = [
                  {
                    type: "video/mp4",
                    src:
                      res.data.uri == null
                        ? this.topicData.explain.mediaFile
                        : res.data.uri,
                  },
                ];
              }
            } else {
              this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
            }
          }
        );
      }

      // this.playerOptions2.sources = [
      //     { type: "video/mp4", src: this.topicData.explain.mediaFile },
      //   ];
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
.Subject-bor {
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #aeaeae;
  //margin-top: 20px;
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #636363;
  .Subject-pl36 {
    padding-left: 30px;
    background: url("~@/assets/WrongQuestion/iconsx1.png") no-repeat left;
  }
  .Subject-borMt15 {
    //margin-top: 15px;
    padding-left: 30px;
  }
}
</style>
