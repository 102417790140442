<template>
  <!-- margin-top: -15px -->
  <div v-if="questionData != null" style="padding: 10px">
    <div class="Subject-topicAsk" v-html="questionData.content"></div>
    <el-divider></el-divider>
    <div
      v-for="(itemAccount, itemAccountIndex) in questionData.accounts"
      :key="questionData.id + '_' + itemAccountIndex"
    >
      <div v-if="itemAccount.name != ''" class="mt15">
        <div class="lhpbtitle">
          {{ itemAccount.label }}
          <i
            style="cursor: pointer"
            class="el-icon-question"
            title="以下是会计分录中包含的科目及金额，请将下列科目及金额拖到下方表格中的适当位置。"
          />
          <i
            style="margin-left: 10px; cursor: pointer"
            class="el-icon-view"
            title="查看做题结果"
            @click="showAccountingPreview"
          />
        </div>
        <div class="itemInput mt15">
          <input v-model="accountAnswers[itemAccountIndex].name" type="text" />
        </div>
      </div>
      <el-row
        v-if="itemAccount.label != '' && itemAccount.name == ''"
        class="lhpbtitle1"
        >{{ itemAccount.label }}
        <el-tooltip
          class="item"
          effect="dark"
          style="cursor: pointer; margin-left: 10px"
          content="以下是会计分录中包含的科目及金额，请将下列科目及金额拖到下方表格中的适当位置。"
          placement="top-end"
        >
          <i style="" class="el-icon-question" />
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          style=" cursor: pointer; margin-left: 10px"
          content="查看做题结果"
          placement="top-end"
        >
          <i
            style="margin-left: 10px; cursor: pointer"
            class="el-icon-view"
            @click="showAccountingPreview"
          />
        </el-tooltip>
      </el-row>
      <el-row class="comprehensive">
        <draggable
          v-model="accountAnswers[itemAccountIndex].accountEntriesOptions"
          :group="'people' + itemAccountIndex"
          itemKey="id"
        >
          <transition-group class="h300BK_top">
            <div
              v-for="(itemEntrie, itemEntrieIndex) in accountAnswers[
                itemAccountIndex
              ].accountEntriesOptions"
              :key="questionData.id + '_options_' + itemEntrieIndex"
              class="w25bf"
            >
              <div :title="itemEntrie.name" class="comprehensive-item">
                {{ itemEntrie.name }}
              </div>
            </div>
          </transition-group>
        </draggable>
      </el-row>
      <el-row class="comprehensive br6" type="flex" align-items="stretch">
        <el-col :span="8">
          <div class="br6_header">科目</div>
          <div
            v-for="(accountEntriesItem, accountEntriesIndex) in accountAnswers[
              itemAccountIndex
            ].accountEntries"
            :key="questionData.id + '_name_' + accountEntriesIndex"
          >
            <!--accountEntriesItem.id-->
            <!-- <div
              style="
                position:absolute;
                border: 1px dashed #707070;
                border-radius: 6px;
                margin: 8px;
                margin-left: 7px;
                width: calc(33.33333% - 16px);
                display: block;
                height: 55px;
              "
            ></div> -->
            <span style="display: none">{{
              (accountAnswerHtml = accountAnswers[itemAccountIndex])
            }}</span>
            <span style="display: none">{{
              (nameList = getEntriesOptionsList(
                accountAnswerHtml.accountEntriesOptions,
                "name"
              ))
            }}</span>

            <div
              v-if="
                accountAnswerHtml.accountEntriesOptions.length > 0 &&
                nameList.length > 0
              "
            >
              <el-popover
                v-model="accountEntriesItem.nameVisible"
                v-if="
                  accountAnswerHtml.accountEntriesOptions.length > 0 &&
                  nameList.length > 0
                "
                placement="right"
                width="400"
                trigger="click"
              >
                <div class="w50bf-item-show">
                  <div
                    v-for="itemEntrie in nameList"
                    :key="questionData.id + '_popover_name_' + itemEntrie.id"
                    class="w50bfContent"
                  >
                    <div
                      @click="
                        addItemFn(
                          itemEntrie,
                          accountAnswers[itemAccountIndex]
                            .accountEntriesOptions,
                          accountAnswers[itemAccountIndex].accountEntries,
                          accountEntriesIndex,
                          'accountEntriesNames'
                        );
                        accountEntriesItem.nameVisible = false;
                      "
                      :title="itemEntrie.name"
                      class="w50bf-item"
                    >
                      {{ itemEntrie.name }}
                    </div>
                  </div>
                </div>
                <div slot="reference">
                  <draggable
                    style="padding: 4px"
                    v-model="
                      accountAnswers[itemAccountIndex].accountEntries[
                        accountEntriesIndex
                      ].accountEntriesNames
                    "
                    :group="'people' + itemAccountIndex"
                    itemKey="id"
                    @add="
                      addDraggable(
                        itemAccountIndex,
                        accountAnswers[itemAccountIndex].accountEntries[
                          accountEntriesIndex
                        ].accountEntriesNames
                      )
                    "
                  >
                    <transition-group class="h300BK">
                      <div
                        class="w25bf"
                        v-for="(
                          accountEntriesNameItem, accountEntriesNameItemIndex
                        ) in accountAnswers[itemAccountIndex].accountEntries[
                          accountEntriesIndex
                        ].accountEntriesNames"
                        :key="
                          questionData.id +
                          '_transition_name_' +
                          accountEntriesNameItemIndex
                        "
                      >
                        <div
                          :title="accountEntriesNameItem.name"
                          class="comprehensive-item"
                        >
                          {{ accountEntriesNameItem.name }}
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </el-popover>
            </div>
            <draggable
              v-else
              style="padding: 4px"
              v-model="
                accountAnswers[itemAccountIndex].accountEntries[
                  accountEntriesIndex
                ].accountEntriesNames
              "
              :group="'people' + itemAccountIndex"
              itemKey="id"
              @add="
                addDraggable(
                  itemAccountIndex,
                  accountAnswers[itemAccountIndex].accountEntries[
                    accountEntriesIndex
                  ].accountEntriesNames
                )
              "
            >
              <transition-group class="h300BK">
                <div
                  class="w25bf"
                  v-for="(
                    accountEntriesNameItem, accountEntriesNameItemIndex
                  ) in accountAnswers[itemAccountIndex].accountEntries[
                    accountEntriesIndex
                  ].accountEntriesNames"
                  :key="
                    questionData.id +
                    '_transition_group_name_' +
                    accountEntriesNameItemIndex
                  "
                >
                  <div
                    :title="accountEntriesNameItem.name"
                    class="comprehensive-item"
                  >
                    {{ accountEntriesNameItem.name }}
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </el-col>
        <el-col
          :span="8"
          style="
            border-left: 1px solid #aeaeae;
            border-right: 1px solid #aeaeae;
          "
        >
          <div class="br6_header">借</div>

          <div
            v-for="(accountEntriesItem, accountEntriesIndex) in accountAnswers[
              itemAccountIndex
            ].accountEntries"
            :key="questionData.id + '_debit_' + accountEntriesIndex"
          >
            <!--accountEntriesItem.id-->
            <div
              v-if="
                accountAnswers[itemAccountIndex].accountEntriesOptions.length >
                  0 &&
                getEntriesOptionsList(
                  accountAnswers[itemAccountIndex].accountEntriesOptions,
                  'value'
                ).length > 0
              "
            >
              <el-popover
                v-model="accountEntriesItem.debitVisible"
                placement="right"
                width="400"
                trigger="click"
              >
                <div class="w50bf-item-show">
                  <div
                    v-for="(
                      itemEntrie, itemEntrieIndex
                    ) in getEntriesOptionsList(
                      accountAnswers[itemAccountIndex].accountEntriesOptions,
                      'value'
                    )"
                    :key="questionData.id + '_popover_debit_' + itemEntrieIndex"
                    class="w50bfContent"
                  >
                    <!-- :key="itemEntrie.id" -->
                    <div
                      @click="
                        addItemFn(
                          itemEntrie,
                          accountAnswers[itemAccountIndex]
                            .accountEntriesOptions,
                          accountAnswers[itemAccountIndex].accountEntries,
                          accountEntriesIndex,
                          'accountEntriesDebits'
                        );
                        accountEntriesItem.debitVisible = false;
                      "
                      :title="itemEntrie.name"
                      class="w50bf-item"
                    >
                      {{ itemEntrie.name }}
                    </div>
                  </div>
                </div>
                <div slot="reference">
                  <draggable
                    style="padding: 4px"
                    v-model="
                      accountAnswers[itemAccountIndex].accountEntries[
                        accountEntriesIndex
                      ].accountEntriesDebits
                    "
                    :group="'people' + itemAccountIndex"
                    itemKey="id"
                    @add="
                      addDraggable(
                        itemAccountIndex,
                        accountAnswers[itemAccountIndex].accountEntries[
                          accountEntriesIndex
                        ].accountEntriesDebits
                      )
                    "
                  >
                    <transition-group class="h300BK">
                      <div
                        class="w25bf"
                        v-for="(
                          accountEntriesValueItem, accountEntriesValueItemIndex
                        ) in accountAnswers[itemAccountIndex].accountEntries[
                          accountEntriesIndex
                        ].accountEntriesDebits"
                        :key="
                          questionData.id +
                          '_transition_debit_' +
                          accountEntriesValueItemIndex
                        "
                      >
                        <!-- :key="accountEntriesValueItem.id" -->
                        <div
                          :title="accountEntriesValueItem.name"
                          class="comprehensive-item"
                        >
                          {{ accountEntriesValueItem.name }}
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </el-popover>
            </div>
            <draggable
              v-else
              style="padding: 4px"
              v-model="
                accountAnswers[itemAccountIndex].accountEntries[
                  accountEntriesIndex
                ].accountEntriesDebits
              "
              :group="'people' + itemAccountIndex"
              itemKey="id"
              @add="
                addDraggable(
                  itemAccountIndex,
                  accountAnswers[itemAccountIndex].accountEntries[
                    accountEntriesIndex
                  ].accountEntriesDebits
                )
              "
            >
              <transition-group class="h300BK">
                <div
                  class="w25bf"
                  v-for="(
                    accountEntriesValueItem, accountEntriesValueIndex
                  ) in accountAnswers[itemAccountIndex].accountEntries[
                    accountEntriesIndex
                  ].accountEntriesDebits"
                  :key="
                    questionData.id +
                    '_transition_group_debit_' +
                    accountEntriesValueIndex
                  "
                >
                  <!-- :key="accountEntriesValueItem.id" -->
                  <div
                    :title="accountEntriesValueItem.name"
                    class="comprehensive-item"
                  >
                    {{ accountEntriesValueItem.name }}
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="br6_header">贷</div>

          <div
            v-for="(accountEntriesItem, accountEntriesIndex) in accountAnswers[
              itemAccountIndex
            ].accountEntries"
            :key="questionData.id + '_credit_' + accountEntriesIndex"
          >
            <div
              v-if="
                accountAnswers[itemAccountIndex].accountEntriesOptions.length >
                  0 &&
                getEntriesOptionsList(
                  accountAnswers[itemAccountIndex].accountEntriesOptions,
                  'value'
                ).length > 0
              "
            >
              <!--accountEntriesItem.id  :key="'credit' + accountEntriesIndex"-->
              <el-popover
                v-model="accountEntriesItem.creditVisible"
                placement="left"
                width="400"
                trigger="click"
              >
                <div class="w50bf-item-show">
                  <div
                    v-for="(
                      itemEntrie, itemEntrieIndex
                    ) in getEntriesOptionsList(
                      accountAnswers[itemAccountIndex].accountEntriesOptions,
                      'value'
                    )"
                    :key="
                      questionData.id + '_popover_credit_' + itemEntrieIndex
                    "
                    class="w50bfContent"
                  >
                    <!-- :key="itemEntrie.id" -->
                    <div
                      @click="
                        addItemFn(
                          itemEntrie,
                          accountAnswers[itemAccountIndex]
                            .accountEntriesOptions,
                          accountAnswers[itemAccountIndex].accountEntries,
                          accountEntriesIndex,
                          'accountEntriesCredit'
                        );
                        accountEntriesItem.creditVisible = false;
                      "
                      :title="itemEntrie.name"
                      class="w50bf-item"
                    >
                      {{ itemEntrie.name }}
                    </div>
                  </div>
                </div>
                <div slot="reference">
                  <draggable
                    style="padding: 4px"
                    v-model="
                      accountAnswers[itemAccountIndex].accountEntries[
                        accountEntriesIndex
                      ].accountEntriesCredit
                    "
                    :group="'people' + itemAccountIndex"
                    itemKey="id"
                    @add="
                      addDraggable(
                        itemAccountIndex,
                        accountAnswers[itemAccountIndex].accountEntries[
                          accountEntriesIndex
                        ].accountEntriesCredit
                      )
                    "
                  >
                    <transition-group class="h300BK">
                      <div
                        class="w25bf"
                        v-for="(
                          accountEntriesTypeItem, accountEntriesTypeItemIndex
                        ) in accountAnswers[itemAccountIndex].accountEntries[
                          accountEntriesIndex
                        ].accountEntriesCredit"
                        :key="
                          questionData.id +
                          '_transition_credit_' +
                          accountEntriesTypeItemIndex
                        "
                      >
                        <!-- :key="accountEntriesTypeItem.id" -->
                        <div
                          :title="accountEntriesTypeItem.name"
                          class="comprehensive-item"
                        >
                          {{ accountEntriesTypeItem.name }}
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </el-popover>
            </div>
            <draggable
              v-else
              style="padding: 4px"
              v-model="
                accountAnswers[itemAccountIndex].accountEntries[
                  accountEntriesIndex
                ].accountEntriesCredit
              "
              :group="'people' + itemAccountIndex"
              itemKey="id"
              @add="
                addDraggable(
                  itemAccountIndex,
                  accountAnswers[itemAccountIndex].accountEntries[
                    accountEntriesIndex
                  ].accountEntriesCredit
                )
              "
            >
              <transition-group class="h300BK">
                <div
                  class="w25bf"
                  v-for="(
                    accountEntriesTypeItem, accountEntriesTypeItemIndex
                  ) in accountAnswers[itemAccountIndex].accountEntries[
                    accountEntriesIndex
                  ].accountEntriesCredit"
                  :key="
                    questionData.id +
                    '_transition_group_credit_' +
                    accountEntriesTypeItemIndex
                  "
                >
                  <!-- :key="accountEntriesTypeItem.id" -->
                  <div
                    :title="accountEntriesTypeItem.name"
                    class="comprehensive-item"
                  >
                    {{ accountEntriesTypeItem.name }}
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="outlineBut" v-if="topicType != null && !isCheck && !isTest">
      <button class="but2" type="button" @click="check">核对</button>
    </div>
    <div
      style="margin-top: 20px"
      v-if="isAnswerSubmit || isCheck"
      :class="getAnswerStyle(questionAnswer.ret)"
    >
      <i></i>
      <span style="margin-left: 10px">{{
        getAnswerTip(questionAnswer.ret)
      }}</span>
      <span style="margin-left: 50px"
        ><span v-if="topicType == 'COMPREHENSIVE'"
          >第{{ questionIndex + 1 }}小</span
        ><span v-else>本</span>题得分率为：<span style="font-weight: 800"
          >{{ scoringRate }}%</span
        ></span
      >
      <div
        class="closeBut"
        v-if="topicType != null && isCheck && !isAnswerSubmit"
      >
        <button class="but2" type="button" @click="isCheck = false">
          关闭
        </button>
      </div>
      <!-- <span style="float: right" class="el-icon-close" ></span> -->
      <div class="Subject-bor">
        <div class="Subject-pl36">答案:</div>
        <div class="Subject-borMt15">
          <div
            v-for="(itemAccount, itemAccountIndex) in questionData.accounts"
            :key="'result_' + itemAccountIndex"
          >
            <div v-if="itemAccount.name != ''" class="mt15">
              <div class="lhpbtitle">{{ itemAccount.label }}</div>
              <div class="itemInput mt15">
                <input
                  type="text"
                  :disabled="true"
                  v-model="itemAccount.name"
                />
              </div>
            </div>
            <el-table
              style="margin-top: 20px"
              border
              :data="itemAccount.accountEntries"
              :show-header="false"
            >
              <el-table-column label="科目" prop="name" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.type == 'DEBIT'"
                    :style="{ color: scope.row.isCorrect ? '' : '#8d0005' }"
                    >借:</span
                  >
                  <span
                    v-else-if="scope.row.type == 'CREDIT'"
                    :style="{ color: scope.row.isCorrect ? '' : '#8d0005' }"
                    >&nbsp;&nbsp;贷:</span
                  >
                  <span :style="{ color: scope.row.isCorrect ? '' : '#8d0005' }"
                    >{{ scope.row.name }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="借" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.type == 'CREDIT'">&nbsp;&nbsp;</span>
                  <span
                    :style="{ color: scope.row.isCorrect ? '' : '#8d0005' }"
                    >{{ scope.row.value }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          v-if="isRichTextNotNull(questionData.comment)"
          class="Subject-pl36"
          style="margin-top: 20px"
        >
          注解:
        </div>
        <div
          v-if="isRichTextNotNull(questionData.comment)"
          class="Subject-borMt15"
        >
          <div class="explainCenter" v-html="questionData.comment"></div>
        </div>
      </div>
    </div>
    <accounting-preview ref="accountingPreview" />
  </div>
</template>
<script>
//import AnswerResultTip from "./AnswerResultTip.vue";
import draggable from "vuedraggable";
import { isRichTextNotNull } from "@/utils/validate";
import AccountingPreview from "./AccountingPreview.vue";
export default {
  name: "Accounting",
  components: { draggable, AccountingPreview }, //, AnswerResultTip
  props: {
    questionData: {
      type: Object,
      default: null,
    },
    presentation: {
      type: String,
      default: null,
    },
    isShowSubjecTitle: {
      type: Boolean,
      default: true,
    },
    isAnswerSubmit: {
      type: Boolean,
      default: false,
    },
    isShowFavorite: {
      //是否显示收藏操作
      type: Boolean,
      default: true,
    },
    refKind: {
      type: String,
      default: null,
    },
    refId: {
      type: Number,
      default: -1,
    },
    topicIndex: {
      type: Number,
      default: 0,
    },
    questionIndex: {
      type: Number,
      default: 0,
    },
    topicType: {
      type: String,
      default: null,
    },
    isTest: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isAnswerSubmit: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        if (val) {
          this.isCheck = true;
        }
      },
    },
    questionData: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {},
    },
    accountAnswers: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        this.questionAnswer.accountAnswers = [];
        let accounts = this.questionData.accounts;
        let accountsLength = accounts.length;
        let scores = 0;
        let correctCounts = 0;
        let questionCounts = 0; //得分总题数
        let answerCount = 0; //填过得答案题数
        for (let l = 0; l < accountsLength; l++) {
          this.questionAnswer.accountAnswers.push({});
          let correctCount = 0;
          let questionCount = 0;
          let account = accounts[l];
          this.questionAnswer.accountAnswers[l].name = "";
          if (account.name != "") {
            if (this.accountAnswers[l].name == account.name) {
              account.isCorrect = true;
              correctCount++;
            }
            questionCounts++;
            questionCount++;
            if (this.accountAnswers[l].name != "") {
              answerCount++;
            }
            this.questionAnswer.accountAnswers[l].name =
              this.accountAnswers[l].name;
          }
          this.questionAnswer.accountAnswers[l].lineList = [];
          let accountEntries = account.accountEntries;
          let accountEntriesLength = accountEntries.length;
          for (let i = 0; i < accountEntriesLength; i++) {
            this.setAccountAnswers(l, this.accountAnswers[l].accountEntries[i]);
            questionCounts++;
            questionCount++;
            for (let m = 0; m < accountEntriesLength; m++) {
              let answersAccountEntries =
                this.accountAnswers[l].accountEntries[m]; //i to m
              if (answersAccountEntries.accountEntriesNames.length > 0) {
                answerCount++;
                if (
                  answersAccountEntries.accountEntriesNames[0].name ==
                  accountEntries[i].name // m to i
                ) {
                  if (accountEntries[i].type == "DEBIT") {
                    // m to i
                    //借判断
                    if (answersAccountEntries.accountEntriesDebits.length > 0) {
                      answerCount++;
                      if (
                        answersAccountEntries.accountEntriesDebits[0].name ==
                        accountEntries[i].value // m to i
                      ) {
                        accountEntries[i].isCorrect = true;
                        correctCount++;
                      }
                    }
                  } else {
                    //贷判断
                    if (answersAccountEntries.accountEntriesCredit.length > 0) {
                      answerCount++;
                      if (
                        answersAccountEntries.accountEntriesCredit[0].name ==
                        accountEntries[i].value // m to i
                      ) {
                        accountEntries[i].isCorrect = true;
                        correctCount++;
                      }
                    }
                  }
                }
              } else {
                continue;
              }
            }
          }
          if (account.score != 0 && correctCount != 0) {
            scores += (account.score / questionCount) * correctCount;
          }
          correctCounts += correctCount;
        }
        this.questionAnswer.scores = scores;
        if (correctCounts > 0 && questionCounts == correctCounts) {
          this.questionAnswer.ret = "CORRECT";
          this.scoringRate = 100;
        } else if (correctCounts > 0) {
          this.scoringRate = (correctCounts / questionCounts) * 100;
          this.questionAnswer.ret = "PARTIAL";
        } else {
          this.scoringRate = (correctCounts / questionCounts) * 100;
          if (answerCount == 0) {
            this.questionAnswer.ret = "BLANK";
          } else {
            this.questionAnswer.ret = "INCORRECT";
          }
        }
        this.questionAnswer.scoringRate = this.scoringRate;
        if (
          this.scoringRate.toString().indexOf(".") != -1 &&
          (this.scoringRate + "").split(".")[1].length > 2
        ) {
          this.scoringRate = this.scoringRate.toFixed(2);
        }
        this.$emit("set-answers", this.questionIndex, this.questionAnswer);
      },
      deep: true,
    },
  },
  data() {
    return {
      scoringRate: 100,
      isShowComment: false,
      isCheck: false,
      questionAnswer: {
        approved: true,
        accountAnswers: [],
        questionId: 0,
        ret: "BLANK",
        scores: 0,
        weight: 0,
      },
      accountAnswers: [],
      count: 0,
    };
  },
  created() {
    this.questionAnswer.questionId = this.questionData.id;
    this.initAccounting();
  },
  methods: {
    initAnswer() {
      this.isCheck = false;
      this.accountAnswers = [];
      this.initAccounting();
    },
    showAccountingPreview() {
      this.$refs["accountingPreview"].showEdit(
        this.questionAnswer.accountAnswers[0].lineList
      );
    },
    addItemFn(row, list, info, index, item) {
      if (info[index][item] && info[index][item].length > 0) {
        var n = undefined;
        list.map((r, i) => {
          if (r.id == row.id && r.name == row.name) {
            n = i;
          }
        });
        list[n] = JSON.parse(JSON.stringify(info[index][item][0]));
        info[index][item] = [row];
      } else {
        info[index][item] = [row];
        var n = undefined;
        list.map((r, i) => {
          if (r.id == row.id && r.name == row.name) {
            n = i;
          }
        });
        list = list.splice(n, 1);
      }
    },
    isRichTextNotNull(comment) {
      return isRichTextNotNull(comment);
    },
    showOrHideComment(bool) {
      this.isShowComment = bool;
    },
    setAccountAnswers(index, accountEntries) {
      let lineList = this.questionAnswer.accountAnswers[index].lineList;
      lineList.push({
        answerName: "",
        answerType: "",
        answerValue: "",
      });

      let linListLength = lineList.length - 1;
      let accountEntriesNames = accountEntries.accountEntriesNames;
      if (accountEntriesNames.length > 0) {
        lineList[linListLength].answerName = accountEntriesNames[0].name;
      }
      let accountEntriesDebits = accountEntries.accountEntriesDebits;
      if (accountEntriesDebits.length > 0) {
        lineList[linListLength].answerType = "DEBIT";
        lineList[linListLength].answerValue = accountEntriesDebits[0].name;
      }
      let accountEntriesCredit = accountEntries.accountEntriesCredit;
      if (accountEntriesCredit.length > 0) {
        lineList[linListLength].answerType = "CREDIT";
        lineList[linListLength].answerValue = accountEntriesCredit[0].name;
      }
    },
    initAccounting() {
      let accounts = this.questionData.accounts;
      let accountsLength = accounts.length;
      for (let l = 0; l < accountsLength; l++) {
        let account = accounts[l];
        if (account.name != "") {
          //this.$set(this.accountAnswers, "name", "");
          this.$set(account, "isCorrect", false);
        }
        this.accountAnswers.push({ name: "" });
        //this.accountAnswers[l].accountEntriesOptions = [];
        //这里必须以这种方式添加，否则无法拖动
        this.$set(this.accountAnswers[l], "accountEntriesOptions", []);
        let accountEntries = account.accountEntries;
        let accountEntriesLength = accountEntries.length;
        //this.accountAnswers[l].accountEntries = [];
        this.$set(this.accountAnswers[l], "accountEntries", []);
        // this.$set(this.accountAnswers[l], "nameVisible", false);
        // this.$set(this.accountAnswers[l], "debitVisible", false);
        // this.$set(this.accountAnswers[l], "creditVisible", false);
        for (let m = 0; m < accountEntriesLength; m++) {
          this.accountAnswers[l].accountEntries.push({});
          let accountEntrieData = accountEntries[m];
          this.$set(accountEntrieData, "isCorrect", false);
          this.accountAnswers[l].accountEntriesOptions.push({
            id: "name_" + accountEntrieData.id,
            name: accountEntrieData.name,
          });
          this.accountAnswers[l].accountEntriesOptions.push({
            id: "value_" + accountEntrieData.id,
            name: accountEntrieData.value,
          });
          this.$set(
            this.accountAnswers[l].accountEntries[m],
            "accountEntriesNames",
            []
          );
          this.$set(
            this.accountAnswers[l].accountEntries[m],
            "nameVisible",
            false
          );
          this.$set(
            this.accountAnswers[l].accountEntries[m],
            "accountEntriesDebits",
            []
          );
          this.$set(
            this.accountAnswers[l].accountEntries[m],
            "debitVisible",
            false
          );
          this.$set(
            this.accountAnswers[l].accountEntries[m],
            "accountEntriesCredit",
            []
          );
          this.$set(
            this.accountAnswers[l].accountEntries[m],
            "creditVisible",
            false
          );
        }
        // this.accountAnswers[l].accountEntriesOptions.sort(function () {
        //   return 0.5 - Math.random();
        // });
        this.accountAnswers[l].accountEntriesOptions.sort(function (a, b) {
          if (a.name > b.name) {
            return -1;
          } else if (a.name < b.name) {
            return 1;
          } else {
            return 0;
          }
        });
      }
    },
    addDraggable(index, answer) {
      if (answer.length > 1) {
        this.accountAnswers[index].accountEntriesOptions.push(answer[1]);
        answer.splice(1, 1);
      }
    },
    isShowSuject(id) {
      if (id.indexOf("name") != -1) {
        return true;
      } else {
        return false;
      }
    },
    getEntriesOptionsList(options, type) {
      let list = [];
      let optionsLength = options.length;
      for (let i = 0; i < optionsLength; i++) {
        if (options[i].id.indexOf("name") != -1 && type == "name") {
          list.push(options[i]);
        } else if (options[i].id.indexOf("name") == -1 && type == "value") {
          list.push(options[i]);
        }
      }
      return list;
    },
    getAnswerStyle(ret) {
      if (ret == "CORRECT") {
        return ["TitleRightItem1"];
      } else if (ret == "BLANK" || ret == "INCORRECT" || ret == "PARTIAL") {
        return ["TitleErrorItem1"];
      } /* else if (ret == "PARTIAL") {
        return ["TitleReportItem1"];
      }*/
    },
    getAnswerTip(ret) {
      if (ret == "CORRECT") {
        return "正确!";
      } else if (ret == "BLANK" || ret == "INCORRECT" || ret == "PARTIAL") {
        return "错误!";
      } /*else if (ret == "PARTIAL") {
        return "正确部分！";
      }*/
    },
    check() {
      this.isCheck = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.Subject-title {
  height: 30px;
  margin-top: 20px;
  .Subject-title-fl {
    font-size: 28px;
    font-weight: bold;
    color: #575b67;
    float: left;
    line-height: 30px;
  }
  .Subject-title-fr {
    float: right;
    height: 30px;
    background: #636363;
    line-height: 30px;
    border-radius: 16px;
    padding: 0 10px;
    i {
      margin-top: 3px;
      float: left;
    }
    span {
      color: #ffffff;
      font-size: 12px;
      font-weight: normal;
      margin-left: 5px;
      float: left;
    }
  }
}
.Subject-or-title {
  line-height: 30px;
  padding: 20px 0 5px 0;
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
.Subject-topicAsk {
  //margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #060606;
}
.Subject-or-text {
  padding-top: 10px;
  textarea {
    padding: 5px 10px;
    border: 1px solid #707070;
    height: 350px;
    line-height: 24px;
    font-size: 16px;
    width: 100%;
  }
}
.mt15 {
  margin-top: 18px;
}
.lhpbtitle {
  font-weight: bold;
  line-height: 32px;
}
.lhpbtitle1 {
  height: 32px;
  //font-size: 16px;
  align-items: center;
  display: flex;
  font-weight: 400;
  color: #060606;
  line-height: 32px;
  margin-top: 15px;
}
.itemInput input {
  height: 45px;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  padding: 0 12px;
  font-size: 14px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #989898;
}
.comprehensive {
  border: 1px solid #aeaeae;
  padding: 4px;
  margin-top: 15px;
  .comprehensive-item {
    height: 49px;
    background: #ffffff;
    box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    border: 1px solid #707070;
    line-height: 49px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #636363;
    cursor: pointer;
    overflow: hidden;
  }
}
.Subject-or {
  margin-top: 10px;
  border: 1px solid #d6d6d6;
  opacity: 1;
  border-radius: 6px;
  dl {
    dd {
      .Subject-orDdTitle {
        padding: 20px 14px;
        label {
          cursor: pointer;
          display: block;
          position: relative;
          input {
            float: left;
            margin-top: 6px;
          }
          div {
            padding-left: 35px;
            padding-right: 35px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #060606;
            opacity: 1;
          }
        }
        i {
          position: absolute;
          right: 0px;
          top: 5px;
        }
      }
      .Subject-info {
        background: #e8e8e8;
        opacity: 1;
        border-radius: 0px;
        padding: 20px 20px 20px 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
        opacity: 1;
      }
    }
  }
}

.outlineBut {
  padding: 10px 0 0 0;
  text-align: center;
  button {
    height: 44px;
    opacity: 1;
    border-radius: 8px;
    padding: 0 20px;
    margin: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
    }
  }
}
.closeBut {
  float: right;
  text-align: center;
  button {
    height: 24px;
    opacity: 1;
    border-radius: 8px;
    padding: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
    }
  }
}

.Subject-If {
  height: 68px;
  background: #dfe9df;
  border: 1px solid #307a35;
  border-radius: 6px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #060606;
  opacity: 1;
  padding: 0 14px;
  margin-top: 20px;
  i {
    float: left;
    margin-top: 20px;
  }
  span {
    float: left;
    margin-left: 18px;
    margin-top: 22px;
  }
}
.br6 {
  border-radius: 6px;
  overflow: hidden;
  text-align: center;
  padding: 0;
  background: #ffffff;
  .br6_header {
    height: 50px;
    background: #e8e8e8;
    border-bottom: 1px solid #aeaeae;
    text-align: center;
    line-height: 50px;
  }
}
.h300BK_top {
  display: block;
  //min-height: 214px;
  height: 49px;
  .w25bf {
    width: 25%;
    display: block;
    float: left;
    padding: 4px;
  }
  .sortable-chosen {
    width: 25%;
    left: auto;
    top: auto;
  }
}
.h300BK {
  display: block;
  //min-height: 210px;
  height: 49px;
  position: relative;
  margin: 4px;
  // &:hover {
  //   background-color: #dddddd;
  //   border-radius: 6px;
  // }
  border: 1px dashed rgb(112, 112, 112);
  &:hover {
    background-color: #dddddd;
  }
  border-radius: 6px;
  .w25bf {
    margin: -1px;
    padding: 0px;
  }
}
.Subject-explain {
  background: #ffffff;
  border: 1px solid #aeaeae;
  opacity: 1;
  border-radius: 6px;
  padding: 15px 20px;
  margin-top: 20px;
  .explainTitle {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    padding-left: 30px;
    background: url("~@/assets/outline/iconDown.png") no-repeat left;
    background-size: 16px 17px;
    line-height: 36px;
  }
  .explainCenter {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    line-height: 24px;
    margin-top: 20px;
  }
  &.on {
    background: #ede2e3;
    border: 1px solid #8d0005;
    opacity: 1;
    border-radius: 6px;
    .explainTitle {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      background: url("~@/assets/outline/naviconitem-3.png") no-repeat left;
    }
    .wrongReason {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      margin-top: 20px;
      padding-left: 30px;
    }
    .explainCenter {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #060606;
      opacity: 1;
      padding-left: 30px;
      a:link,
      a:visited {
        color: #8d0005;
      }
    }
  }
}

.Subject-bor {
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #aeaeae;
  margin-top: 20px;
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #636363;
  .Subject-pl36 {
    padding-left: 30px;
    background: url("~@/assets/WrongQuestion/iconsx1.png") no-repeat left;
  }
  .Subject-borMt15 {
    margin-top: 15px;
    padding-left: 30px;
  }
}
</style>
<style>
.itemSpan p,
.inWor p {
  display: inline;
  white-space: pre-line;
}
.SorDdTitle .el-radio__input,
.SorDdTitle .el-checkbox__input {
  float: left;
  margin-top: 2px;
}
.SorDdTitle .el-radio__label,
.SorDdTitle .el-checkbox__label {
  display: block;
  padding-left: 20px;
  line-height: 20px;
}
</style>

<style lang="scss" scoped>
.TitleRightItem1 {
  background: #dfe9df;
  border-radius: 6px;
  border: 1px solid #b7a114;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/outline/naviconitem-2.png") no-repeat center;
    background-size: cover;
  }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.TitleErrorItem1 {
  background: #ede2e3;
  border-radius: 6px;
  border: 1px solid #8d0005;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/outline/naviconitem-3.png") no-repeat center;
    background-size: cover;
  }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.TitleReportItem1 {
  background: #fffcea;
  border-radius: 6px;
  border: 1px solid #307a35;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/TrueTopic/gdhao.png") no-repeat center;
    background-size: cover;
  }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

.w50bf-item-show {
  max-height: 350px;
  overflow: auto;
  .w50bfContent {
    width: 50%;
    float: left;
    padding: 5px;
    box-sizing: border-box;
    .w50bf-item {
      box-sizing: border-box;
      height: 49px;
      background: #ffffff;
      box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      border: 1px solid #707070;
      line-height: 49px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #636363;
      cursor: pointer;
      overflow: hidden;
    }
  }
  &::after {
    clear: both;
    display: block;
    content: "";
  }
}
</style>
