<template>
  <!-- ; margin-top: -15px -->
  <div v-if="questionData != null" style="padding: 10px">
    <div class="Subject-or-title" v-html="questionData.content"></div>
    <div class="Subject-or">
      <dl>
        <el-checkbox-group v-model="answer">
          <dd
            v-for="(choiceItem, choiceIndex) in questionData.choices"
            :key="choiceIndex"
          >
            <div class="Subject-orDdTitle SorDdTitle">
              <el-checkbox
                :key="choiceItem.label"
                :label="choiceItem.label"
                name="type"
                :value="choiceItem.label"
              >
                <span
                  class="inWor"
                  v-html="choiceItem.label + '.' + choiceItem.content"
                ></span>
              </el-checkbox>
            </div>
          </dd>
        </el-checkbox-group>
      </dl>
    </div>
    <div class="outlineBut" v-if="topicType != null && !isCheck && !isTest">
      <button class="but2" type="button" @click="check">核对</button>
    </div>

    <!-- <answer-result-tip
      style="margin-top: 20px"
      :questionAnswer="questionAnswer"
      :isAnswerSubmit="isAnswerSubmit"
      @show-or-hide-comment="showOrHideComment"
    />
    <div v-if="isShowComment" class="Subject-bor">
      <div
        v-if="
          questionAnswer.ret == 'INCORRECT' || questionAnswer.ret == 'BLANK'
        "
      >
        <div class="Subject-pl36">答案:</div>
        <div class="Subject-borMt15">
          <span
            v-for="(choiceItem, choiceIndex) in questionData.choices"
            :key="choiceIndex"
          >
            <span v-if="choiceItem.choice == true">
              {{ choiceItem.label }}
            </span>
          </span>
        </div>
      </div>
      <div
        v-if="questionData.comment != ''"
        class="Subject-pl36"
        style="margin-top: 20px"
      >
        注解:
      </div>
      <div v-if="questionData.comment != ''" class="Subject-borMt15">
        <div class="explainCenter" v-html="questionData.comment"></div>
      </div>
    </div> 
    -->
    <div
      style="margin-top: 20px"
      v-if="isAnswerSubmit || isCheck"
      :class="getAnswerStyle(questionAnswer.ret)"
    >
      <i></i>
      <span style="margin-left: 10px">{{
        getAnswerTip(questionAnswer.ret)
      }}</span>
      <span style="margin-left: 50px"
        ><span v-if="topicType == 'COMPREHENSIVE'"
          >第{{ questionIndex + 1 }}小</span
        >
        <span v-else>本</span>题得分率为：<span style="font-weight: 800"
          >{{ scoringRate }}%</span
        ></span
      >
      <div
        class="closeBut"
        v-if="topicType != null && isCheck && !isAnswerSubmit"
      >
        <button class="but2" type="button" @click="isCheck = false">
          关闭
        </button>
      </div>
      <div class="Subject-bor" >
        <div>
          <div class="Subject-pl36">答案:</div>
          <div class="Subject-borMt15">
            <span
              v-for="(choiceItem, choiceIndex) in questionData.choices"
              :key="choiceIndex"
            >
              <span v-if="choiceItem.choice == true">
                {{ choiceItem.label }}
              </span>
            </span>
          </div>
        </div>
        <div
          v-if="isRichTextNotNull(questionData.comment)"
          class="Subject-pl36"
          style="margin-top: 20px"
        >
          注解:
        </div>
        <div
          v-if="isRichTextNotNull(questionData.comment)"
          class="Subject-borMt15"
        >
          <div class="explainCenter" v-html="questionData.comment"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import AnswerResultTip from "./AnswerResultTip.vue";
import { isRichTextNotNull } from "@/utils/validate";
export default {
  name: "MultipleChoice",
  components: {}, //AnswerResultTip
  props: {
    questionData: {
      type: Object,
      default: null,
    },
    presentation: {
      type: String,
      default: null,
    },
    isShowSubjecTitle: {
      type: Boolean,
      default: true,
    },
    isAnswerSubmit: {
      type: Boolean,
      default: false,
    },
    isShowFavorite: {
      //是否显示收藏操作
      type: Boolean,
      default: true,
    },
    refKind: {
      type: String,
      default: null,
    },
    refId: {
      type: Number,
      default: -1,
    },
    topicIndex: {
      type: Number,
      default: 0,
    },
    questionIndex: {
      type: Number,
      default: 0,
    },
    topicType: {
      type: String,
      default: null,
    },
    isTest: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShowComment: false,
      answer: null,
      rightAnswer: [],
      isCheck: false,
      scoringRate: 100,
      questionAnswer: {
        approved: true,
        choicesAnswers: [],
        questionId: 0,
        ret: "BLANK",
        scores: 0,
        weight: 0,
      },
    };
  },
  watch: {
    isAnswerSubmit: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        if (val) {
          this.isCheck = true;
        }
      },
    },
    answer: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        if (this.scalarArrayEquals(this.answer, this.rightAnswer)) {
          this.questionAnswer.scores = this.questionData.score;
          this.questionAnswer.ret = "CORRECT";
          this.scoringRate = 100;
        } else {
          this.questionAnswer.scores = 0;
          this.scoringRate = 0;
          if (this.answer.length == 0) {
            this.questionAnswer.ret = "BLANK";
          } else {
            this.questionAnswer.ret = "INCORRECT";
          }
        }
        this.questionAnswer.choicesAnswers = [];
        for (let i = 0; i < this.questionData.choices.length; i++) {
          let choice = this.questionData.choices[i];
          for (let j = 0; j < this.answer.length; j++) {
            if (choice.label == this.answer[j]) {
              this.questionAnswer.choicesAnswers.push({
                itemId: choice.id,
                itemLabel: choice.label,
                itemValue: choice.content,
              });
            }
          }
        }
        this.questionAnswer.scoringRate = this.scoringRate;
        this.$emit("set-answers", this.questionIndex, this.questionAnswer);
      },
      deep: true,
    },
  },
  created() {
    this.questionAnswer.questionId = this.questionData.id;
    this.setRightAnswer();
    this.answer = [];
  },
  methods: {
    initAnswer() {
      this.answer = [];
      this.isCheck = false;
    },
    isRichTextNotNull(comment) {
      return isRichTextNotNull(comment);
    },
    answerSubmit() {
      this.isAnswerSubmit = true;
    },
    showOrHideComment(bool) {
      this.isShowComment = bool;
    },
    scalarArrayEquals(array1, array2) {
      const answers = [];
      for (let i = 0; i < array1.length; i++) {
        answers.push(array1[i]);
      }
      answers.sort();
      return (
        answers.length == array2.length &&
        answers.every(function (v, i) {
          return v === array2[i];
        })
      );
    },
    setRightAnswer() {
      for (let i = 0; i < this.questionData.choices.length; i++) {
        if (this.questionData.choices[i].choice) {
          this.rightAnswer.push(this.questionData.choices[i].label);
        }
      }
    },
    getAnswerStyle(ret) {
      if (ret == "CORRECT") {
        return ["TitleRightItem1"];
      } else if (ret == "BLANK" || ret == "INCORRECT" || ret == "PARTIAL") {
        return ["TitleErrorItem1"];
      } /* else if (ret == "PARTIAL") {
        return ["TitleReportItem1"];
      }*/
    },
    getAnswerTip(ret) {
      if (ret == "CORRECT") {
        return "正确!";
      } else if (ret == "BLANK" || ret == "INCORRECT" || ret == "PARTIAL") {
        return "错误!";
      } /*else if (ret == "PARTIAL") {
        return "正确部分！";
      }*/
    },
    check() {
      this.isCheck = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.Subject-title {
  height: 30px;
  margin-top: 20px;
  .Subject-title-fl {
    font-size: 28px;
    font-weight: bold;
    color: #575b67;
    float: left;
    line-height: 30px;
  }
  .Subject-title-fr {
    float: right;
    height: 30px;
    background: #636363;
    line-height: 30px;
    border-radius: 16px;
    padding: 0 10px;
    i {
      margin-top: 3px;
      float: left;
    }
    span {
      color: #ffffff;
      font-size: 12px;
      font-weight: normal;
      margin-left: 5px;
      float: left;
    }
  }
}
.Subject-or-title {
  line-height: 30px;
  //padding: 20px 0 5px 0;
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
.Subject-or {
  margin-top: 10px;
  border: 1px solid #d6d6d6;
  opacity: 1;
  border-radius: 6px;
  dl {
    dd {
      .Subject-orDdTitle {
        padding: 10px 14px;
        label {
          cursor: pointer;
          display: block;
          position: relative;
          input {
            float: left;
            margin-top: 6px;
          }
          div {
            padding-left: 35px;
            padding-right: 35px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #060606;
            opacity: 1;
          }
        }
        i {
          position: absolute;
          right: 0px;
          top: 5px;
        }
      }
      .Subject-info {
        background: #e8e8e8;
        opacity: 1;
        border-radius: 0px;
        padding: 20px 20px 20px 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
        opacity: 1;
      }
    }
  }
}

.outlineBut {
  //padding: 30px 0;
  padding: 10px 0 0 0;
  text-align: center;
  button {
    height: 44px;

    opacity: 1;
    border-radius: 8px;
    padding: 0 20px;
    margin: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
    }
  }
}
.closeBut {
  float: right;
  text-align: center;
  button {
    height: 24px;
    opacity: 1;
    border-radius: 8px;
    padding: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #173d7c;
    }
  }
}

.Subject-If {
  height: 68px;
  background: #dfe9df;
  border: 1px solid #307a35;
  border-radius: 6px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  color: #060606;
  opacity: 1;
  padding: 0 14px;
  margin-top: 20px;
  i {
    float: left;
    margin-top: 20px;
  }
  span {
    float: left;
    margin-left: 18px;
    margin-top: 22px;
  }
}

.Subject-explain {
  background: #ffffff;
  border: 1px solid #aeaeae;
  opacity: 1;
  border-radius: 6px;
  padding: 15px 20px;
  margin-top: 20px;
  .explainTitle {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    padding-left: 30px;
    background: url("~@/assets/outline/iconDown.png") no-repeat left;
    background-size: 16px 17px;
    line-height: 36px;
  }
  .explainCenter {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    line-height: 24px;
    margin-top: 20px;
  }
  &.on {
    background: #ede2e3;
    border: 1px solid #8d0005;
    opacity: 1;
    border-radius: 6px;
    .explainTitle {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      background: url("~@/assets/outline/naviconitem-3.png") no-repeat left;
    }
    .wrongReason {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      margin-top: 20px;
      padding-left: 30px;
    }
    .explainCenter {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #060606;
      opacity: 1;
      padding-left: 30px;
      a:link,
      a:visited {
        color: #8d0005;
      }
    }
  }
}

.Subject-bor {
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #aeaeae;
  margin-top: 20px;
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #636363;
  .Subject-pl36 {
    padding-left: 30px;
    background: url("~@/assets/WrongQuestion/iconsx1.png") no-repeat left;
  }
  .Subject-borMt15 {
    margin-top: 15px;
    padding-left: 30px;
  }
}
</style>
<style>
.itemSpan p,
.inWor p {
  display: inline;
  white-space: pre-line;
}
.SorDdTitle .el-radio__input,
.SorDdTitle .el-checkbox__input {
  float: left;
  margin-top: 2px;
}
.SorDdTitle .el-radio__label,
.SorDdTitle .el-checkbox__label {
  display: block;
  padding-left: 20px;
  line-height: 20px;
}
</style>

<style lang="scss" scoped>
.TitleRightItem1 {
  background: #dfe9df;
  border-radius: 6px;
  border: 1px solid #b7a114;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/outline/naviconitem-2.png") no-repeat center;
    background-size: cover;
  }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.TitleErrorItem1 {
  background: #ede2e3;
  border-radius: 6px;
  border: 1px solid #8d0005;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/outline/naviconitem-3.png") no-repeat center;
    background-size: cover;
  }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.TitleReportItem1 {
  background: #fffcea;
  border-radius: 6px;
  border: 1px solid #307a35;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/TrueTopic/gdhao.png") no-repeat center;
    background-size: cover;
  }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
</style>
