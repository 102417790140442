<template>
  <el-dialog :title="title" :visible.sync="dialogFormVisible" @close="close">
    <el-table :data="data" style="width: 100%" :show-header="false">
      <el-table-column min-width="180">
        <template slot-scope="scope">
          <span v-html="getTypeName(scope.row.answerType)" />
          {{ scope.row.answerName }}
        </template>
      </el-table-column>
      <el-table-column min-width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.answerType == 'CREDIT'">&nbsp;&nbsp;</span>
          {{ scope.row.answerValue }}
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  name: "AccountingPreview",
  data() {
    return {
      data: [],
      title: "预览会计分录",
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    showEdit(data) {
      this.data = data;
      this.dialogFormVisible = true;
      console.log(JSON.stringify(data));
    },
    getTypeName(_type) {
      if (_type == "DEBIT") {
        return "借:";
      } else if (_type == "CREDIT") {
        return "&nbsp;&nbsp;贷:";
      }
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>
